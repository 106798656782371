import { useDispatch, useSelector } from 'react-redux';
import {
  uploadFile,
  setAlert,
  setProgress,
  setFiles,
} from '../store/downloadFile';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { RootState } from '../store';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useDownloadFile } from './useDownloadFile';

export const useHandleFileChange = (
  cancelCondition: boolean,
  updateCancelCondition: (value: boolean) => void
) => {
  const dispatch =
    useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const progress = useSelector((state: RootState) => state.fileUpload.progress);
  const { startRequestLoop, stopRequestLoop } = useDownloadFile();

  useEffect(() => {
    if (cancelCondition) {
      stopRequestLoop();
      dispatch(setFiles(null));
      dispatch(setProgress(0));
      updateCancelCondition(false);
    }
  }, [cancelCondition]);

  const handleFileChange = async (
    event: ChangeEvent<HTMLInputElement>,
    id: number | undefined
  ) => {
    dispatch(setFiles(null));
    if (event.target.files && event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        dispatch(setFiles({ file: event.target.files[i].name, done: false }));
      }

      const allFiles = event.target.files;
      for (let i = 0; i < allFiles.length; i++) {
        dispatch(setAlert({ alertText: '', alert: false }));
        dispatch(setProgress(progress));

        const selectedFile = event.target ? event?.target?.files[i]! : null;
        // @ts-ignore
        const error = await startRequestLoop(selectedFile, id);
        if (error) {
          return;
        }

        dispatch(setProgress(0));
        dispatch(setFiles({ file: event.target.files[i].name, done: true }));
      }
    }
  };
  return handleFileChange;
};
