import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import {CheckboxWrapper} from "./styled";

interface CheckboxCustomProps {
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    color?: 'default' | 'primary' | 'secondary';
    inputProps?: object;
    label?: string;
}

const CheckboxCustom: React.FC<CheckboxCustomProps> = (
    {
        checked,
        onChange,
        color = 'primary',
        inputProps = {},
        label
    }) => {
    return(
        <CheckboxWrapper>
            <Checkbox checked={checked} onChange={onChange} color={color} inputProps={inputProps} />
            {label}
        </CheckboxWrapper>
        );
};

export default CheckboxCustom;
