import {
  ElementType,
  FC,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
} from 'react';

import * as S from './styled';
import { ErrorWrapper } from '../modals/saveEditUser/styled';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  ref?: ForwardedRef<HTMLInputElement>;
  errors?: any;
  label?: string;
  /**
   * currency code / Icon / IconButton at start of input
   */
  startAdornment?: string | ReactElement;
  /**
   * currency code / Icon / IconButton at end of input
   */
  endAdornment?: string | ReactElement;
  disableNumberArrows?: boolean;
  nonEditable?: boolean;
  showErrorMessage?: boolean;
};

export const Input: FC<any> = forwardRef((props, ref) => {
  const {
    name,
    errors,
    label,
    startAdornment,
    endAdornment,
    disableNumberArrows,
    nonEditable,
    showErrorMessage = true,
    className = '',
    disabled,
    ...rest
  } = props;

  // const hasError = errors.find() ? errors: false;
  const Wrapper = label ? S.LabelWrapper : (S.InputWrapper as ElementType);

  const calculateAdornmentIndent = (
    adornment: InputProps['startAdornment']
  ) => {
    if (!adornment) return false;

    const adornmentIndentForCurrency = 60;
    const adornmentIndentForIcons = 48;

    if (typeof adornment === 'string') {
      return adornmentIndentForCurrency;
    }

    return adornmentIndentForIcons;
  };
  //TODO
  const getInputClassName = () => {
    let inputClassName = className;
    if (startAdornment) {
      inputClassName += ' start-adornment';
    }
    if (endAdornment) {
      inputClassName += ' end-adornment';
    }
    if (nonEditable) {
      inputClassName += ' non-editable';
    }
    if (disableNumberArrows) {
      inputClassName += ' disable-number-arrows';
    }
    // if (hasError) {
    //   inputClassName += ' error';
    // }
    return inputClassName;
  };

  return (
    <Wrapper>
      {label && <S.Label>{label}</S.Label>}
      <S.Input
        {...rest}
        ref={ref}
        name={name}
        className={getInputClassName()}
        readOnly={nonEditable}
        $startAdornmentIndent={calculateAdornmentIndent(startAdornment)}
        $endAdornmentIndent={calculateAdornmentIndent(endAdornment)}
        disabled={disabled}
      />
      {/* {showErrorMessage && hasError && <ErrorMessage fieldName={name} errors={errors} />} */}
      {startAdornment && (
        <S.InputAdornment
          $type='start'
          $disabled={disabled}
          $nonEditable={nonEditable}
        >
          {startAdornment}
        </S.InputAdornment>
      )}
      {endAdornment && (
        <S.InputAdornment
          $type='end'
          $disabled={disabled}
          $nonEditable={nonEditable}
        >
          {endAdornment}
        </S.InputAdornment>
      )}
      <ErrorWrapper>
        {errors?.map(
          (error: any) =>
            error.field === name && <div key={error.field}>{error.message}</div>
        )}
      </ErrorWrapper>
    </Wrapper>
  );
});
