import styled, { css } from 'styled-components';

export const Input = styled.input<{
  $startAdornmentIndent: number | false;
  $endAdornmentIndent: number | false;
}>`
  height: 42px;
  // width: 100%;
  padding-left: 12px;

  border-radius: 4px;
  border: 1px solid #C7CFD6;
  background: #FFF;

  color: #5D636B;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */

  &::placeholder {
    color: #A1A1A1;
    font-family: Open Sans;
 
  }

  &:hover {
    border-color: #A1A1A1;
    outline: none;
  }

  &:active,
  &:focus,
  &:focus-visible {
    border-color: #5D636B;
    outline: none;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:active,
  &:disabled:focus,
  &:disabled:focus-visible {
    border: none;
    color: #A1A1A1;
    cursor: not-allowed;
  }

  // custom classes
  &.error {
    border-color: red;
  }

  &.non-editable {
    border: none;
    color: #A1A1A1;
  }

  &.disable-number-arrows {
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    [type='number'] {
      -moz-appearance: textfield;
    }
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const LabelWrapper = styled.label`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.span`
  display: inline-block;
  margin-bottom: 4px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  
  color: #0A0A0A;
  margin-left: 3px;

`;

export const InputAdornment = styled.span<{
  $type: 'start' | 'end';
  $disabled?: boolean;
  $nonEditable?: boolean;
}>`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 48px;
  margin: 0 16px;

  font-weight: 400;
  font-size: 1.6rem;
  color: #5D636B;

  ${({ $type }) => {
    switch ($type) {
      case 'start':
        return css`
          left: 0;
        `;
      case 'end':
        return css`
          right: 0;
        `;
    }
  }};

  ${({ $disabled }) =>
    $disabled &&
    css`
      color: #A1A1A1;
    `};
  ${({ $nonEditable }) =>
    $nonEditable &&
    css`
      color: #A1A1A1;
    `};
`;
