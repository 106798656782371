import { FC } from 'react';

export const IconFileUpload: FC = () => {
  return (
    <svg
      width='20'
      height='19'
      viewBox='0 0 20 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ verticalAlign: 'middle' }}
    >
      <path
        d='M10 0.0859375L15.914 5.99994L14.5 7.41394L11 3.91394V13.4999H9V3.91394L5.5 7.41394L4.086 5.99994L10 0.0859375ZM2.5 11.4999V16.4999H17.5V11.4999H19.5V18.4999H0.5V11.4999H2.5Z'
        fill='#9B9A9A'
      />
    </svg>
  );
};
