import styled from "styled-components";

export const PageWrapper = styled.div`

`;
export const TitleWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  height: 32px;
  align-items: flex-end;
`;

export const ContentContainer = styled.div<{ $gap?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap:  10px;
  width: 100%;
  margin: 0;
  padding-bottom: 5px;
`;

export const FolderNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const IconFolderContainer = styled.div`
  width: 18px;
  height: 18px;
`;

export const NameBox = styled.div`
  width: 18px;
  height: 18px;
`;

export const DescriptionBox = styled.div`
  width: 700px;
  height: auto;
  padding: 8px 10px 8px 10px;
  border-radius: 4px;
  border: 1px;
  gap: 8px;
  
  border: 1px solid #BECFBE;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 244px;
  height: 189px;
  padding: 8px 10px 12px 10px;
  border-radius: 4px;
  border: 1px;
  gap: 4px;
  border: 1px solid #BECFBE;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export const ImageName = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  min-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FilesContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

export const ImageBox = styled.div`
  background-color: #e1e5ea;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  img {
    width: 244px;
    height: 161px;
    object-fit: contain;
  }
`;

export const LoadingBox = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Loader = styled.span`
  width: 68px;
  height: 68px;
  border: 7px solid #e1e5ea;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
`