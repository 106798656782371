import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Users } from './pages/users/Users';
import { Login } from './pages/login/Login';
import { Header } from './components/Header';
import { SideBar } from './components/sideBar';
import { Roles } from './pages/roles/Roles';
import { Folders } from './pages/folders/Folders';
import { View } from './pages/view/ViewSVS';
import { Case } from './pages/case/Case';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import { UploadInfoWindow } from './components/download/UploadInfoWindow';

function App() {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('access');
  const is_admin = localStorage.getItem('is_admin');
  const progress = useSelector((state: RootState) => state.fileUpload.progress);
  const files = useSelector((state: RootState) => state.fileUpload.files);
  const fileName = useSelector((state: RootState) => state.fileUpload.fileName);
  const uploadFiles = useSelector((state: RootState) => state.fileUpload.files);
  const [sortedFiles, setSortedFiles] = useState<
    {
      file: string;
      done: boolean;
    }[]
  >([]);

  const [cancelCondition, setCancelCondition] = useState(false);

  const updateCancelCondition = (value: boolean) => {
    setCancelCondition(value);
  };
  useEffect(() => {
    if (files.length > 0) {
      const filesCopy = [...files];
      const filterData = filesCopy.sort((a, b) => {
        return a.done === b.done ? 0 : a.done ? -1 : 1;
      });
      setSortedFiles(filterData);
    }
  }, [files]);

  useEffect(() => {
    if (!!accessToken === false && window.location.pathname !== '/login') {
      localStorage.clear();
      navigate('/login');
    }
  }, [accessToken]);

  if (accessToken) {
    if (is_admin == 'false') {
      return (
        <>
          <div style={{ display: 'flex', overflow: 'hidden' }}>
            <SideBar></SideBar>
            <div style={{ width: '100%' }}>
              <Header></Header>
              <div style={{ margin: '20px', height: 'calc(100vh - 200px)' }}>
                <Routes>
                  <Route
                    path='*'
                    element={
                      <Folders
                        cancelCondition={cancelCondition}
                        updateCancelCondition={updateCancelCondition}
                      />
                    }
                  ></Route>
                  <Route
                    path='/folders'
                    element={
                      <Folders
                        cancelCondition={cancelCondition}
                        updateCancelCondition={updateCancelCondition}
                      />
                    }
                  ></Route>
                  <Route
                    path='/folders/:id'
                    element={
                      <Folders
                        cancelCondition={cancelCondition}
                        updateCancelCondition={updateCancelCondition}
                      />
                    }
                  ></Route>
                  <Route path='/case/:id' element={<Case />}></Route>
                  <Route path='/view' element={<View />}></Route>
                </Routes>
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div style={{ display: 'flex', overflow: 'hidden' }}>
          <SideBar></SideBar>
          {fileName && uploadFiles.length > 0 && (
            <UploadInfoWindow
              files={sortedFiles}
              progress={progress}
              fileName={fileName}
              updateCancelCondition={updateCancelCondition}
            />
          )}
          <div style={{ width: '100%' }}>
            <Header></Header>
            <div style={{ margin: '20px', height: 'calc(100vh - 200px)' }}>
              <Routes>
                <Route path='*' element={<Users />}></Route>
                <Route path='/users' element={<Users />}></Route>
                <Route path='/roles' element={<Roles />}></Route>
                <Route
                  path='/folders'
                  element={
                    <Folders
                      cancelCondition={cancelCondition}
                      updateCancelCondition={updateCancelCondition}
                    />
                  }
                ></Route>
                <Route
                  path='/folders/:id'
                  element={
                    <Folders
                      cancelCondition={cancelCondition}
                      updateCancelCondition={updateCancelCondition}
                    />
                  }
                ></Route>
                <Route path='/case/:id' element={<Case />}></Route>
                <Route path='/view' element={<View />}></Route>
              </Routes>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Routes>
        <Route path='/login' element={<Login />}></Route>
        <Route path='*' element={<Login />}></Route>
      </Routes>
    </>
  );
}

export default App;
