import styled, { css } from 'styled-components';
import {
  FormControlLabel as MuiControlLabel,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
} from '@mui/material';

// RadioGroup

export const RadioGroup = styled(MuiRadioGroup)`
  &.MuiFormGroup-root {
    gap: 16px;
  }

  &.MuiFormGroup-row {
    gap: 16px;
  }
`;

// RadioControlLabel
export const RadioControlLabel = styled(MuiControlLabel)`
  &.MuiFormControlLabel-root {
    margin: 0;
    gap: 8px;
  }

  &.MuiFormControlLabel-root.Mui-disabled {
    opacity: 0.3;
  }

  .MuiFormControlLabel-label {
    //styleName: small txt;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    
    color: #0A0A0A;
  }

  .MuiFormControlLabel-label.Mui-disabled {
    color: gray;
  }

  &.MuiFormControlLabel-root.Mui-disabled {
    opacity: 1;
  }
`;

// Radio
export const Radio = styled(MuiRadio)`
  &.MuiRadio-root {
    padding: 0;
  }
`;

const radioIconCommonStyles = css`
  width: 18px;
  height: 18px;
  border-radius: 50%;
`;

export const RadioIcon = styled.div`
  ${radioIconCommonStyles};
  border: 2px solid #9B9A9A;
  background-color: transparent;
`;

export const RadioIconChecked = styled.div`
  ${radioIconCommonStyles};
  border: 2px solid #006666;
  background-color: transparent;
  box-shadow: inset 0 0 0px 6px #FFF, inset 0 0 0 16px #006666;
`;

export const RadioRowLabelContainer = styled.div`
  span {
    color: #9B9A9A;
  }
`;
