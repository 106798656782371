import {combineReducers, configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query';
import { userReducer } from "./user";
import { headerButtonReducer } from "./headerButton";
import {fileUploadReducer} from "./downloadFile";

const rootReducer = combineReducers({
    user: userReducer,
    headerButton: headerButtonReducer,
    fileUpload: fileUploadReducer,
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: false
    }),
});


setupListeners(store.dispatch);

export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
