import React, { FC, useEffect, useMemo, useState } from 'react';
import { IconFile } from '../../icons/IconFile';
import { UploadBanner } from './styled';
import { IconButton } from '../IconButton/IconButton';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { IconDone } from '../../icons/IconDone';
import { IconArrowTopBottom } from '../../icons/IconArrowTopBottom';
import { IconClose } from '../../icons/IconCLose';
import ConfirmModal from '../modals/confirmModal/ConfirmModal';
import { useDispatch } from 'react-redux';
import { setFiles } from '../../store/downloadFile';

interface UploadInfoWindowProps {
  files: {
    file: string;
    done: boolean;
  }[];
  progress: number;
  fileName: string;
  updateCancelCondition: (value: boolean) => void;
}

export const UploadInfoWindow: FC<UploadInfoWindowProps> = ({
  files,
  progress,
  updateCancelCondition,
  fileName,
}) => {
  const { t, i18n } = useTranslation();
  const [showFiles, setShowFiles] = useState(false);
  const dispatch = useDispatch();

  const [closeSending, setCloseSending] = useState(false);
  const [stopSendingModalOpen, setStopSendingModalOpen] = useState(false);

  const [everyDone, setEveryDone] = useState(false);

  useEffect(() => {
    if (files.length > 0) {
      const filesCopy = [...files];
      const isEveryDone = filesCopy.every((a) => a.done);
      if (isEveryDone) {
        setEveryDone(true);
      } else {
        setEveryDone(false);
      }
    }
  }, [files]);

  const handleCloseSending = () => {
    updateCancelCondition(true);
    setStopSendingModalOpen(false);
    setCloseSending(false);
  };

  const handleCloseOpen = () => {
    const filesCopy = [...files];
    const isEveryDone = filesCopy.every((a) => a.done);

    if (isEveryDone) {
      dispatch(setFiles(null));
    } else {
      setCloseSending(true);
      setStopSendingModalOpen(true);
    }
  };
  const handleCloseClose = () => {
    setStopSendingModalOpen(false);
    setCloseSending(false);
  };
  const deleteModal = useMemo(() => {
    if (closeSending) {
      return (
        <ConfirmModal
          open={stopSendingModalOpen}
          onClose={handleCloseClose}
          onSubmit={() => handleCloseSending()}
          submitButtonText={t('ui.button.stop')}
          title={t('ui.modal.title.stopUploadTitle')}
        />
      );
    }
  }, [closeSending]);

  return (
    <UploadBanner>
      <div className='header'>
        {!everyDone
          ? t('uploadInfoWindow.loading', { count: files.length })
          : files.length === 1
          ? t('uploadInfoWindow.finishedOne')
          : t('uploadInfoWindow.finishedFew')}
        <div className='controls'>
          <div
            onClick={() => setShowFiles((prev) => !prev)}
            style={!showFiles ? { transform: 'rotate(180deg)' } : {}}
          >
            <IconArrowTopBottom />
          </div>

          <div onClick={handleCloseOpen}>
            <IconClose />
          </div>
        </div>
      </div>
      {showFiles && (
        <div className='content'>
          {!everyDone && (
            <div className='cancelBtn' onClick={handleCloseOpen}>
              {t('ui.button.cancel')}
            </div>
          )}
          <div className='files'>
            {files.map((file) => (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <IconButton icon={<IconFile />} />
                  <div
                    style={{
                      maxWidth: '300px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      textWrap: 'nowrap',
                    }}
                  >
                    {file.file}
                  </div>
                </div>
                <div
                  style={{
                    paddingRight: '10px',
                    height: '22px',
                    width: '22px',
                  }}
                >
                  {!file.done ? (
                    <div>
                      <svg
                        viewBox='0 0 100 100'
                        style={{ height: '22px', width: '22px' }}
                        className='progressBackground'
                      >
                        <circle cx='50' cy='50' r='46' />
                      </svg>
                      <CircularProgress
                        variant='determinate'
                        value={file.file === fileName ? progress : 0}
                        style={{
                          height: '22px',
                          width: '22px',
                          marginLeft: '-22px',
                        }}
                        color='primary'
                      />
                    </div>
                  ) : (
                    <IconDone />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {deleteModal}
    </UploadBanner>
  );
};

export default UploadBanner;
