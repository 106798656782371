import { useTranslation } from 'react-i18next';
import { IconLogout } from '../../icons/IconLogout';
import { Button } from '../button';
import {
  HeaderButtonConainer,
  HeaderContainer,
  HeaderContainerContainer,
  HeaderDateContainer,
  HeaderTextContainer,
} from './styled';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { store } from '../../store';
import { getCurrentUser } from '../../axios/staff/staffApi';

export const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const currentDate = new Date().toLocaleDateString();

  const [content, setContent] = useState<any[]>();
  const [name, setName] = useState('Администратор');
  const nameOfInstitution = process.env.REACT_APP_NAME_OF_INSITUTION;
  const ruDate = new Intl.DateTimeFormat('ru', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    weekday: 'long',
  })
    .format(new Date())
    .replace(/(\s?\г\.?)/, '');

  store.subscribe(() => {
    if (content != store.getState().headerButton.content) {
      setContent(store.getState().headerButton.content);
    }
  });
  useEffect(() => {
    getCurrentUser().then((res) => {
      if (
        res.data.first_name !== '' &&
        res.data.first_name !== null &&
        res.data.first_name !== undefined
      ) {
        setName(res.data.first_name);
      }
    });
  }, []);

  const onLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  const buttonContent = useMemo(() => <>{content}</>, [content]);

  return (
    <HeaderContainer>
      <HeaderContainerContainer>
        <HeaderTextContainer>
          Цифровой архив {nameOfInstitution}
        </HeaderTextContainer>
        <div style={{ position: 'relative' }}>
          <HeaderDateContainer>{ruDate}</HeaderDateContainer>
        </div>
      </HeaderContainerContainer>

      <HeaderButtonConainer>
        <div>{buttonContent}</div>
        <Button
          style={{ color: '#475F7B', fontSize: '22px' }}
          variant='text'
          body={t('header.logout')}
          onClick={onLogout}
          endIcon={<IconLogout />}
        ></Button>
      </HeaderButtonConainer>
    </HeaderContainer>
  );
};
function useAppActions(rightPanelActions: any): {
  removeRightPanelContent: any;
} {
  throw new Error('Function not implemented.');
}
