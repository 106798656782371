import axios, { AxiosResponse } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { checkFileName } from '../axios/case/caseApi';
import { useDispatch } from 'react-redux';
import {
  setAlert,
  setCaseId,
  setFileName,
  setFlag,
  setProgress,
} from '../store/downloadFile';

export const useDownloadFile = () => {
  const cancelTokenSource = useRef<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {};
  }, []);
  const startRequestLoop = async (file: File, id: number) => {
    let result: string | AxiosResponse<any, any> = '';
    const chunkSize = 1024 * 1024 * 2;
    const fileSize = file.size;
    let offset = 0;
    const chunks: [number, number, Blob][] = [];

    const cancelToken = axios.CancelToken.source();
    cancelTokenSource.current = cancelToken;
    if (file.name && id) {
      await checkFileName(id, file.name)
        .then(async (res) => {
          try {
            dispatch(setCaseId(id));
            dispatch(setFlag(false));
            dispatch(setFileName(file.name));

            while (offset + chunkSize < fileSize) {
              chunks.push([
                offset,
                offset + chunkSize,
                file.slice(offset, offset + chunkSize),
              ]);
              offset += chunkSize;
            }
            chunks.push([offset, fileSize, file.slice(offset)]);

            const progressPart = 100.0 / chunks.length;

            const token = localStorage.getItem('access');
            const promises: Promise<void>[] = [];
            let i = 0;
            while (i < chunks.length) {
              const x = chunks[i];
              const form = new FormData();
              form.append('part', x[2]);
              form.append('part_number', i.toString());
              form.append('filename', file.name);
              form.append('filetype', file.type);
              const promise = axios
                .post(
                  `${process.env.REACT_APP_BASE_URL}/case/${id}/upload/`,
                  form,
                  {
                    headers: {
                      Connection: 'keep-alive',
                      'Keep-Alive': 'timeout=10, max=1000',
                      Authorization: `Bearer ${token}`,
                      'Content-Range': `bytes ${x[0]}-${x[1]}/${fileSize}`,
                      'X-File-Chunk': 'chunk',
                    },
                    cancelToken: cancelToken.token,
                  }
                )
                .then(() => {
                  dispatch(setProgress(progressPart));
                })
                .catch((err) => {
                  result = 'error';
                });
              await new Promise((resolve) => setTimeout(resolve, 300));
              // @ts-ignore
              promises.push(promise);
              i++;
            }
            await Promise.all(promises);

            const form = new FormData();
            form.append('filename', file.name);
            form.append('filetype', file.type);
            await axios.post(
              `${process.env.REACT_APP_BASE_URL}/case/${id}/upload/`,
              form,
              {
                headers: {
                  Connection: 'keep-alive',
                  'Keep-Alive': 'timeout=10, max=1000',
                  Authorization: `Bearer ${token}`,
                  'X-File-Chunk': 'Complete',
                },
                cancelToken: cancelToken.token,
              }
            );
          } catch (err) {}
        })
        .finally(() => {
          dispatch(setFlag(true));
        })
        .catch(() => {
          dispatch(
            setAlert({
              alertText: 'Файл с таким названием уже существует',
              alert: true,
            })
          );
          console.log('Цикл запросов был остановлен');
        });
    }
    return result;
  };

  const stopRequestLoop = () => {
    console.log(cancelTokenSource.current);

    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel(
        'Остановка цикла запросов пользователем'
      );
    }
  };
  return { startRequestLoop, stopRequestLoop };
};
