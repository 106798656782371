import {axiosInstance} from "../index";
import {UserArg} from "../role/type";

export function getUsers(offset: number, limit: number) {
    return axiosInstance.get(`/staff/?offset=${offset}&limit=${limit}`)
}


export function getAllUsers() {
    return axiosInstance.get(`/staff/?offset=0&limit=100`)
}

export function getCurrentUser() {
    return axiosInstance.get(`/staff/whoami/`)
}

export function getUserbyId(id: number) {
    return axiosInstance.get(`/staff/${id}/`)
}

export function addUser(data: UserArg) {
    return axiosInstance.post(`/staff/`, data)
}

export function updateUser(data: UserArg) {
    return axiosInstance.patch(`/staff/${data.id}/`, data)
}

export function deleteUser(id: number) {
    return axiosInstance.delete(`/staff/${id}/`)
}
