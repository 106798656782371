import {axiosInstance} from "../index";
import { CaseDTO } from "./type";

export function postCreateCase(data: CaseDTO) {
    return axiosInstance.post(`/case/`, data)
}

export function getCase(id: number) {
    return axiosInstance.get(`/case/${id}/`)
}

export function deleteCase(id: number) {
    return axiosInstance.delete(`/case/${id}/`)
}

export function updateCase(id: number, date: CaseDTO) {
    return axiosInstance.patch(`/case/${id}/`, date)
}
// export function postCreateFolderInFolderInRoot(folderId: string, data: Folder) {
//     return axiosInstance.post(`/folder/${folderId}/node/`, data)
// }

export function getFile(id: number) {
    return axiosInstance.get(`/case/file/${id}/`)
}

export function testGet(path: string) {
    return axiosInstance.get(`${path}`)
}

export function getStatus(id: number) {
    return axiosInstance.get(`/case/file/${id}/status/`)
}

export function checkFileName(id: number, filename: string){
    const newFileName = {
        "filename": filename
    }
    return axiosInstance.post(`/case/${id}/options/`, newFileName, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
