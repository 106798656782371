import { ButtonWrapper, DownloadWrapper, FileItem } from './styled';
import * as S from '../customInput/styled';
import React, { ElementType, FC, useMemo, useState } from 'react';
import Dow from './Dow';
import { CaseFile } from '../../axios/case/type';
import { IconCross } from '../../icons/IconCross';
import { IconButton } from '../IconButton';
import { IconFile } from '../../icons/IconFile';
import { checkStatus, deleteFile } from '../../axios/file/fileApi';
import { IconEye } from '../../icons/IconEye';
import { getFile, getStatus } from '../../axios/case/caseApi';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, store } from '../../store';
import { ErrorWrapper } from '../modals/saveEditUser/styled';

export type DownloadProps = {
  label?: string;
  id?: number;
  files?: any;
  fileError?: {
    show: boolean;
    text: string;
  };
  onDeleteFileById: (id: number) => void;
  showErrorFile: (text: string) => void;
  cancelCondition: boolean;
  handleFileChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number | undefined
  ) => Promise<void>;
};

export const Download: FC<DownloadProps> = (props) => {
  const {
    label,
    id,
    files,
    showErrorFile,
    onDeleteFileById,
    fileError,
    cancelCondition,
    handleFileChange,
  } = props;
  const progress = useSelector((state: RootState) => state.fileUpload.progress);
  const file = useSelector((state: RootState) => state.fileUpload.file);
  const uploadFiles = useSelector((state: RootState) => state.fileUpload.files);
  const navigate = useNavigate();
  const flag = useSelector((state: RootState) => state.fileUpload.flag);
  const fileName = useSelector((state: RootState) => state.fileUpload.fileName);
  const caseId = useSelector((state: RootState) => state.fileUpload.caseId);
  const handleDeleteFile = (id: number) => {
    // deleteFile(id).then(() => {
    //
    // })
    onDeleteFileById(id);
  };

  const handleViewFile = (id: number) => {
    getStatus(id).then((res) => {
      if (res.data.is_conversion) {
        getFile(id).then((res) => {
          const dzi_path = res.data.dzi_path;
          const image_path = res.data.image_path;
          navigate(`/view?image_path=${image_path}&dzi_path=${dzi_path}`);
        });
      } else {
        showErrorFile('Файл обрабатывается');
      }
    });
  };
  const handleCheckStatus = (id: number) => {
    checkStatus(id).then((res) => {
      console.log(res.data);
      //если is_conversion false значит файл не собрался/не сконвертировался/
      //и его открывать низя
    });
  };
  const fileViewer = useMemo(() => {
    return files?.map((obj: CaseFile) => (
      <div key={obj.id}>
        {/*id: {obj.id}; file name: {obj.name}; path: {obj.path}*/}
        <FileItem>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton icon={<IconFile />} />
            {obj.name}
          </div>
          <div style={{ display: 'flex' }}>
            <IconButton
              icon={<IconEye />}
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                handleViewFile(obj.id as number);
              }}
            />
            <IconButton
              icon={<IconCross />}
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                handleDeleteFile(obj.id as number);
              }}
            />
          </div>
        </FileItem>
      </div>
    ));
  }, [files]);

  return (
    <div>
      {label && <S.Label>{label}</S.Label>}
      <DownloadWrapper>
        {fileViewer}
        {caseId == id &&
          uploadFiles.length > 0 &&
          uploadFiles.map((file) => {
            if (!file.done) {
              return (
                <FileItem key={file.file}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: '10px',
                    }}
                  >
                    <IconButton icon={<IconFile />} />
                    <div style={{ paddingRight: '8px' }}>{file.file}</div>
                    {
                      <progress
                        value={file.file === fileName ? progress : 0}
                        max={100}
                      ></progress>
                    }
                  </div>
                  <div>{/* Добавьте необходимые кнопки, если они нужны */}</div>
                </FileItem>
              );
            }
            // В случае, если условие !file.done не выполняется, возвращаем null
            return null;
          })}
      </DownloadWrapper>
      {fileError?.show && <ErrorWrapper>{fileError.text}</ErrorWrapper>}
      {!(progress != 0 && !flag) && (
        <ButtonWrapper>
          <Dow
            id={id}
            cancelCondition={cancelCondition}
            handleFileChange={handleFileChange}
          ></Dow>
        </ButtonWrapper>
      )}
    </div>
  );
};
