import styled, { css } from 'styled-components';

import { IconButton } from '../IconButton';
import { Button } from '../button';

export const PaginationContainer = styled.nav`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 24px;
`;

export const PagesWrapper = styled.div`
  display: flex;
  gap: 8px;
`;
export const ArrowsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const PaginationItem = styled.div`
  display: flex;
  align-items: center;
`;

export const PaginationPagesContainer = styled.div`
display: flex;
border: 1px solid #C7CFD6;
border-radius: 4px;
`;

export const PaginationButton = styled(Button)<{ $selected: boolean }>`
  height: 32px;
  min-width: 32px !important;
  width: 32px;

  // padding: 8px;
  border: none !important;
  border-radius: 4px;
  font-weight: 700;
  color: #3F3356 !important;

  ${({ $selected }) =>
    $selected &&
    css`
      color: #FFFFFF !important;
      background: #006666 !important;
    `};
`;

export const PaginationArrows = styled(IconButton)<{ $disabled?: boolean }>`
  height: 34px;
  width: 34px;

  padding: 8px;
  border-radius: 4px !important;
  border: 1px solid #C7CFD6 !important;

  .MuiTouchRipple-child {
    border-radius: 8px;
  }
  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5 !important;;
    `};
`;

export const PaginationEllipsis = styled.div`
  display: flex;
  justify-content: center;
  width: 30px;

  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
`;
