import styled from "styled-components";

export const Title = styled.div`
  color: #006666;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;

  display: flex;
  gap: 4px;

  cursor: pointer;
}
`;
