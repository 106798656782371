import { FC, forwardRef, ReactNode } from 'react';
import {
  IconButtonProps as MuiIconButtonProps,
  Tooltip,
  TooltipProps,
} from '@mui/material';

import * as S from './styled';

type IconButtonProps = MuiIconButtonProps & {
  tooltipTitle?: NonNullable<ReactNode>;
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
  icon: string | JSX.Element;
};

export const IconButton: FC<any> = forwardRef((props, ref) => {
  const { tooltipTitle, tooltipProps, icon, children, ...rest } = props;

  const iconElement = typeof icon === 'string' ? <S.Icon src={icon} /> : icon;

  if (tooltipTitle) {
    return (
      <Tooltip title={tooltipTitle} {...tooltipProps}>
        <S.IconButton {...rest}>{iconElement}</S.IconButton>
      </Tooltip>
    );
  }

  return (
    <S.IconButton ref={ref} {...rest}>
      {iconElement}
      {children}
    </S.IconButton>
  );
});
