import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppActions } from '../../store/utils';
import { headerButtonActions } from '../../store/headerButton';
import { Button } from '../../components/button';
import { IconPlus } from '../../icons/IconPlus';
import { usePagination } from '../../hooks/usePagination';
import { getRoles } from '../../axios/role/roleApi';
import {
  deleteFolder,
  getFolders,
  getFoldersInFolders,
} from '../../axios/folder/folderApi';
import { ContentContainer, PageWrapper } from '../roles/style';
import {
  HeadCell,
  StyledTableRow,
  Table,
  TableCell,
} from '../../components/Table';
import { Pagination } from '../../components/Pagination';
import SaveEditRoleModal from '../../components/modals/saveEditRoleModal/SaveEditRoleModal';
import { IconButton } from '../../components/IconButton';
import { IconCross } from '../../icons/IconCross';
import { IconEdit } from '../../icons/IconEdit';
import { IconFolderTable } from '../../icons/IconFolderTable';
import { FolderNameContainer, IconFolderContainer } from './style';
import { IconCase } from '../../icons/IconCase';
import SaveEditFolderModal from '../../components/modals/saveEditFolderModal/SaveEditFolderModal';
import ConfirmModal from '../../components/modals/confirmModal/ConfirmModal';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import SaveEditCaseModal from '../../components/modals/saveEditCaseModal/saveEditCaseModal';
import { deleteCase } from '../../axios/case/caseApi';
import { hasPermision } from '../../utils/utils';
import { IconFileUpload } from '../../icons/IconFileUpload';
import { useHandleFileChange } from '../../hooks/useHandleFileChange';
import { UploadBtnIcon } from '../../components/download/styled';
interface FoldersProps {
  cancelCondition: boolean;
  updateCancelCondition: (value: boolean) => void;
}
export const Folders: FC<FoldersProps> = ({
  cancelCondition,
  updateCancelCondition,
}) => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const handleFileChange = useHandleFileChange(
    cancelCondition,
    updateCancelCondition
  );

  const [folders, setFolders] = useState([]);
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [openFolder, setOpenFolder] = useState(false);
  const [openCase, setOpenCase] = useState(false);
  const [editOpenFolder, setEditOpenFolder] = useState(false);
  const [editOpenCase, setEditOpenCase] = useState(false);
  const [deleteFolderOpen, setDeleteFolderOpen] = useState(false);
  const [deleteCaseOpen, setDeleteCaseOpen] = useState(false);
  const [selectFolder, setSelectFolder] = useState<number | undefined>();
  const [selectCase, setSelectCase] = useState<number | undefined>();
  const { pagination, onPageChange, resetPagination } = usePagination({
    offset: 0,
    limit: 7,
    pageNumber: 0,
  });
  useEffect(() => {
    if (searchParams.get('case')) {
      try {
        setSelectCase(Number(searchParams.get('case')) ?? undefined);
        setEditOpenCase(true);
      } catch {}
    }
  }, []);
  useEffect(() => {
    const offset =
      pagination.pageNumber + 1 != 1
        ? pagination.limit * (pagination?.pageNumber ?? 1)
        : 0;
    id
      ? getFoldersInFolders(id, offset, pagination.limit).then((data) => {
          setFolders(data.data.child.results);
          setBreadCrumb(data.data.bread_crumbs);
          setTotalCount(data.data.child.count);
        })
      : getFolders(offset, pagination.limit).then((data) => {
          setFolders(data.data.results);
          setTotalCount(data.data.count);
        });
  }, [pagination.pageNumber, id]);

  useEffect(() => {
    if (
      Math.ceil((totalCount ?? 0) / pagination.limit) <
        pagination.pageNumber + 1 &&
      pagination.pageNumber + 1 > 1 &&
      !folders.length
    ) {
      onPageChange(pagination.pageNumber - 1);
    }
  }, [totalCount, pagination.pageNumber]);

  useEffect(() => {
    resetPagination();
  }, [id]);

  const handleOpenFolder = () => {
    setOpenFolder(true);
  };

  const handleOpenCase = () => {
    setOpenCase(true);
  };

  const { setHeaderButtonComponent } = useAppActions(headerButtonActions);
  const { removeHeaderButtonContent } = useAppActions(headerButtonActions);

  useEffect(() => {
    removeHeaderButtonContent();
  }, []);
  useEffect(() => {
    setHeaderButtonComponent(
      <div>
        {hasPermision(1) && (
          <Button
            onClick={handleOpenFolder}
            style={{ marginRight: '16px' }}
            startIcon={<IconPlus />}
            variant={'contained'}
            body={t('ui.button.createFolder')}
          />
        )}
        {hasPermision(2) && (
          <Button
            onClick={handleOpenCase}
            startIcon={<IconPlus />}
            variant={'contained'}
            body={t('ui.button.createCase')}
          />
        )}
      </div>
    );
  }, []);

  const handleCloseFolder = () => {
    setOpenFolder(false);
  };

  const handleCloseCase = () => {
    setOpenCase(false);
  };

  const handleEditOpen = (id: number, type: string) => {
    if (type == 'folder') {
      setSelectFolder(id);
      setEditOpenFolder(true);
    } else {
      setSelectCase(id);
      setEditOpenCase(true);
    }
  };

  const handleEditClose = () => {
    setEditOpenFolder(false);
    setSelectFolder(undefined);
    setEditOpenCase(false);
    setSelectCase(undefined);
  };
  const handleDeleteOpen = (id: number, type: string) => {
    if (type == 'folder') {
      setSelectFolder(id);
      setDeleteFolderOpen(true);
    } else {
      setSelectCase(id);
      setDeleteCaseOpen(true);
    }
  };

  const handleDeleteClose = () => {
    setDeleteFolderOpen(false);
    setDeleteCaseOpen(false);
    setSelectFolder(undefined);
    setSelectCase(undefined);
  };

  const handleSave = () => {
    const offset =
      pagination.pageNumber + 1 != 1
        ? pagination.limit * (pagination?.pageNumber ?? 1)
        : 0;
    id
      ? getFoldersInFolders(id, offset, pagination.limit).then((data) => {
          setFolders(data.data.child.results);
          setBreadCrumb(data.data.bread_crumbs);
          setTotalCount(data.data.child.count);
        })
      : getFolders(offset, pagination.limit).then((data) => {
          setFolders(data.data.results);
          setTotalCount(data.data.count);
        });
    setOpenFolder(false);
    setEditOpenCase(false);
    setOpenCase(false);
    setEditOpenFolder(false);
    setDeleteFolderOpen(false);
    setDeleteCaseOpen(false);
  };

  const handleDeleteCase = (id: number) => {
    deleteCase(id).then(() => {
      handleSave();
    });
  };
  const handleDeleteFolder = (id: number) => {
    deleteFolder(id).then(() => {
      handleSave();
    });
  };
  const headCells: HeadCell[] = [
    {
      disablePadding: true,
      label: t('ui.table.name'),
      // width: 10px,
      numeric: false,
      id: '1',
    },
    {
      disablePadding: true,
      width: '300px',
      label: t('ui.table.owner'),
      numeric: false,
      id: '4',
    },
    {
      disablePadding: true,
      width: '300px',
      label: t('ui.table.dateOfCreate'),
      numeric: false,
      id: '2',
    },
    {
      disablePadding: true,
      width: '300px',
      label: t('ui.table.dateOfChange'),
      numeric: false,
      id: '3',
    },
  ];

  const editModal = useMemo(() => {
    if (selectFolder) {
      return (
        <SaveEditFolderModal
          open={editOpenFolder}
          onClose={handleEditClose}
          onSave={handleSave}
          isEdit={true}
          folderId={selectFolder}
        />
      );
    }
    if (selectCase) {
      return (
        <SaveEditCaseModal
          open={editOpenCase}
          onClose={handleEditClose}
          onSave={handleSave}
          isEdit={true}
          caseId={selectCase}
          parentFolderId={id}
          cancelCondition={cancelCondition}
          handleFileChange={handleFileChange}
        />
      );
    }
  }, [editOpenCase, editOpenFolder, selectCase, selectFolder]);

  const deleteModal = useMemo(() => {
    if (selectFolder) {
      return (
        <ConfirmModal
          open={deleteFolderOpen}
          onClose={handleDeleteClose}
          onSubmit={() => handleDeleteFolder(selectFolder)}
          submitButtonText={t('ui.button.delete')}
          title={t('ui.modal.title.confirmDeleteFolder')}
        />
      );
    }
    if (selectCase) {
      return (
        <ConfirmModal
          open={deleteCaseOpen}
          onClose={handleDeleteClose}
          onSubmit={() => handleDeleteCase(selectCase)}
          submitButtonText={t('ui.button.delete')}
          title={t('ui.modal.title.confirmDeleteCase')}
        />
      );
    }
  }, [deleteFolderOpen, deleteCaseOpen, selectCase, selectFolder]);

  const tableRows = useMemo(() => {
    return folders?.map((row: any, index: number) => (
      <StyledTableRow
        key={index}
        onClick={() =>
          row.type === 'folder'
            ? navigate(`/folders/${row.id}`)
            : navigate(`/case/${row.id}`)
        }
      >
        <TableCell align='left'>
          <FolderNameContainer>
            <IconFolderContainer>
              {row.type === 'folder' ? (
                <IconFolderTable />
              ) : (
                <IconCase></IconCase>
              )}
            </IconFolderContainer>
            {row.name}
          </FolderNameContainer>
        </TableCell>
        <TableCell align='left'>
          {row.staff && `${row.staff?.last_name} ${row.staff?.first_name}`}
        </TableCell>
        <TableCell align='left'>{row.datetime_create}</TableCell>
        <TableCell align='left'>{row.datetime_update}</TableCell>
        <TableCell align='right' width={'150px'}>
          {
            <div
              onClick={(e) => e.stopPropagation()}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <label style={{ color: '#006666', cursor: 'pointer' }}>
                <UploadBtnIcon onClick={(e: any) => {}}>
                  <IconFileUpload />
                </UploadBtnIcon>
                <input
                  type='file'
                  accept='.svs,.kfb,.tif,.ndpi'
                  onClick={(e: any) => (e.target.value = [])}
                  onChange={(e) => {
                    handleFileChange(e, row.id);
                  }}
                  style={{ display: 'none' }}
                  multiple={true}
                />
              </label>

              <IconButton
                icon={<IconEdit />}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleEditOpen(row.id, row.type);
                }}
              />
              <IconButton
                icon={<IconCross />}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleDeleteOpen(row.id, row.type);
                }}
              />
            </div>
          }
        </TableCell>
      </StyledTableRow>
    ));
  }, [folders]);

  const breadCrumbComponent = useMemo(() => {
    const items = [
      ...breadCrumb.map((it: any) => {
        return {
          id: it.id,
          name: it.name,
          path: `/folders/${it.id}`,
        };
      }),
      { id: '', name: t('menus.folders'), path: '/folders' },
    ];
    return <Breadcrumb items={items} navigate={navigate} />;
  }, [breadCrumb]);

  return (
    <>
      <PageWrapper>
        <ContentContainer>
          {id && breadCrumbComponent}
          <Table headCells={headCells} rows={tableRows} />
          <Pagination
            pageNumber={pagination.pageNumber ?? 0}
            pageCount={Math.ceil((totalCount ?? 0) / pagination.limit)}
            onChange={onPageChange}
          />
        </ContentContainer>
        <SaveEditFolderModal
          open={openFolder}
          onClose={handleCloseFolder}
          onSave={handleSave}
          isEdit={false}
          parentFolderId={id}
        />
        <SaveEditCaseModal
          open={openCase}
          onClose={handleCloseCase}
          onSave={handleSave}
          isEdit={false}
          parentFolderId={id}
          cancelCondition={cancelCondition}
          handleFileChange={handleFileChange}
        />
        {editModal}
        {deleteModal}
      </PageWrapper>
    </>
  );
};
