import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type HeaderButton = any;

type CommonState = {
  content: HeaderButton | undefined;
};

const initialState: CommonState = {
  content: undefined,
};

export const headerButton = createSlice({
  name: 'headerButton',
  initialState,
  reducers: {
    setHeaderButtonComponent: (state, action: PayloadAction<HeaderButton>) => {
      state.content = action.payload;
    },
    removeHeaderButtonContent: (state) => {
      state.content = undefined;
    },
  },
});

export const headerButtonActions = headerButton.actions;
export const headerButtonReducer = headerButton.reducer;
