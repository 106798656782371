import React from 'react';
import {BackButton, BreadcrumbContainer, Link, Separator} from "./styled";
import { IconButton } from '../IconButton';
import { IconBack } from '../../icons/IconBack';
import { Button } from '../button';
import { useTranslation } from 'react-i18next';
import { To } from 'react-router-dom';

interface BreadcrumbProps {
    items: {
        id: string | number;
        name: string;
        path: string;
    }[];
    navigate: (path: string | To) => void;
}

function Breadcrumb({ items, navigate }: BreadcrumbProps) {
    return (
        <BreadcrumbContainer>
            <BackButton style={{maxWidth: '80px'}} variant={'text'} startIcon={<IconBack />} onClick={() => navigate(-1 as To)} body={'Назад'}/>
            {items.reverse().map((item, index) => (
                <React.Fragment key={item.id}>
                    <Link active={index == items.length - 1} style={{cursor: `${index == items.length - 1 ? 'auto' : 'pointer'}`}} onClick={() => navigate(item.path)}>{item.name}</Link>
                    {index < items.length - 1 && <Separator>/</Separator>}
                </React.Fragment>
            ))}
        </BreadcrumbContainer>
    );
}

export default Breadcrumb;
