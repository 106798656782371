import { FC, forwardRef } from 'react';
import { CircularProgress } from '@mui/material';
import type { LoadingButtonProps } from '@mui/lab';

import * as S from './styled';

export type ButtonIconSize = 'small' | 'medium';
export type ButtonProps = LoadingButtonProps & {
  body: string;
  /**
   * If you use src for icon - use it property for sizing
   */
  iconSize?: ButtonIconSize;
};

export const Button: FC<any> = forwardRef((props, ref) => {
  const {
    endIcon,
    startIcon,
    iconSize = 'small',
    body,
    variant = 'outlined',
    ...rest
  } = props;

  const endIconElement =
    typeof endIcon === 'string' ? (
      <S.Icon src={endIcon} $iconSize={iconSize} />
    ) : (
      endIcon
    );
  const startIconElement =
    typeof startIcon === 'string' ? (
      <S.Icon src={startIcon} $iconSize={iconSize} />
    ) : (
      startIcon
    );

  return (
    <S.Button
      {...rest}
      ref={ref}
      variant={variant}
      endIcon={endIconElement}
      startIcon={startIconElement}
      sx={{ textTransform: 'none' }}
      loadingIndicator={<CircularProgress color='secondary' size={16} />}
    >
      {body}
    </S.Button>
  );
});
