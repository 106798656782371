import styled from "styled-components";

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const PageWrapper = styled.div`
  width: 430px;
  background-color: #F5F7FA;
  // box-shadow: 3px 12px 24px 0px rgba(129, 137, 197, 0.20);
  display: flex;
  border: 1px solid #C7CFD6;
  border-radius: 5px;
  flex-direction: column;
  padding: 40px;
  padding-bottom: 16px;
  align-items: center;
`;

export const ContentWrapper = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 20px 50px 0 50px;
`

export const LoginLogoWraper = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;

  img {
    height: 127px;
  }
`;