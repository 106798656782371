import React, {useEffect, useMemo, useState} from "react";
import { useTranslation } from "react-i18next";
import {useAppActions} from "../../store/utils";
import {headerButtonActions} from "../../store/headerButton";
import {Button} from "../../components/button";
import {IconPlus} from "../../icons/IconPlus";
import {usePagination} from "../../hooks/usePagination";
import {getRoles} from "../../axios/role/roleApi";
import {deleteFolder, getFolders, getFoldersInFolders} from "../../axios/folder/folderApi";
import {ContentContainer, PageWrapper} from "../roles/style";
import {HeadCell, StyledTableRow, Table, TableCell} from "../../components/Table";
import {Pagination} from "../../components/Pagination";
import SaveEditRoleModal from "../../components/modals/saveEditRoleModal/SaveEditRoleModal";
import { IconButton } from "../../components/IconButton";
import { IconCross } from "../../icons/IconCross";
import { IconEdit } from "../../icons/IconEdit";
import { IconFolderTable } from "../../icons/IconFolderTable";
import { DescriptionBox, FilesContainer, FolderNameContainer, IconFolderContainer, ImageBox, ImageContainer, ImageName, Loader, LoadingBox } from "./style";
import { IconCase } from "../../icons/IconCase";
import SaveEditFolderModal, { PermissionType } from "../../components/modals/saveEditFolderModal/SaveEditFolderModal";
import ConfirmModal from "../../components/modals/confirmModal/ConfirmModal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import SaveEditCaseModal from "../../components/modals/saveEditCaseModal/saveEditCaseModal";
import { deleteCase, getCase, getFile, getStatus, testGet } from "../../axios/case/caseApi";
import { hasPermision } from "../../utils/utils";
import { UserArg } from "../../axios/role/type";
import { getAllUsers } from "../../axios/staff/staffApi";
import { CaseDTO } from "../../axios/case/type";
import axios from "axios";
import { Input } from "../../components/customInput";
import { Label } from "../../components/customInput/styled";
import { ErrorWrapper } from "../../components/modals/saveEditUser/styled";

export function Case() {
	const { t, i18n } = useTranslation();
    const { id } = useParams();

    const navigate = useNavigate();

    const [breadCrumb, setBreadCrumb] = useState([]);
    const [errorFile, showErrorFile] = useState('');
    const [caseData, setCaseData] = useState<CaseDTO>({} as CaseDTO);
    const [filePaths, setFilePaths] = useState<{name:string; imageBlob: string, id: number}[]>([]);

    useEffect(() => {
        getCase(Number(id) ?? 0).then(({data}) => {
            const userList = data?.white_list.length
                ? data.white_list?.map((item: any) => { return {id: item.id, name: `${item.first_name} ${item.last_name}`}})
                : data.black_list?.map((item: any) => { return {id: item.id, name: `${item.first_name} ${item.last_name}`}})
            setCaseData({
                ...data,
                black_list: userList,
                white_list: userList
            });
            setBreadCrumb(data.bread_crumbs)
        })
    }, [id]);

    useEffect(() => {
        if (caseData.files?.length && caseData.id && !filePaths.length) {
            caseData.files.forEach(async (it) => {
                getStatus(it.id ?? 0).then(async (res) => {
                    if (res.data.is_conversion) {
                        const path = await getFile(it?.id ?? 0)
                        const token = localStorage.getItem('access')
                        const response = await fetch(`${process.env.REACT_APP_BASE_URL_WITHOUT_API}${path.data.image_path}/8/0_0.jpeg`, {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        if (!response.ok) {
                            throw new Error('Ошибка загрузки изображения');
                        }
                    
                        const blob = await response.blob();
                        const imageUrl = URL.createObjectURL(blob);
                        
                        setFilePaths((prevState: any) => {
                            return [
                                ...prevState,
                                {
                                    name: it.name,
                                    imageBlob: imageUrl,
                                    id: it.id
                                }
                            ]
                        })
                    } else {
                        setFilePaths((prevState: any) => {
                            return [
                                ...prevState,
                                {
                                    name: it.name,
                                }
                            ]
                        })
                    }
                })
                
            });
        }
    }, [caseData])

    const { setHeaderButtonComponent } = useAppActions(headerButtonActions);
    const { removeHeaderButtonContent } = useAppActions(headerButtonActions);

    useEffect(() => {
        removeHeaderButtonContent();
    }, []);

    const handleViewFile = (id: number) => {
        getStatus(id)
            .then((res) => {
                if (res.data.is_conversion) {
                    getFile(id)
                        .then((res) => {
                            const dzi_path = res.data.dzi_path
                            const image_path = res.data.image_path
                            navigate(`/view?image_path=${image_path}&dzi_path=${dzi_path}`)
                        })
                } else {
                    showErrorFile('Файл обрабатывается');
                    setTimeout(() => {
                        showErrorFile('');
                    }, 5000)
                }
            })
    };

    const breadCrumbComponent = useMemo(() => {
        const items = [
            {id: id, name: caseData.name ?? '...', path: ''},
            ...breadCrumb.map((it: any) => {
                return {
                    id: it.id,
                    name: it.name,
                    path: `/folders/${it.id}`
                }
            }),
            {id: '', name: t('menus.folders'), path: '/folders'},
        ]
        return (
            <Breadcrumb items={items} navigate={navigate} />
        )
    }, [breadCrumb, caseData]);

    const files = useMemo(() => filePaths?.map((it) => {
        if (it.id) {
            return (<ImageContainer onClick={() => handleViewFile(it.id)}>
                <ImageName>
                    {it.name}
                </ImageName>
                <ImageBox>
                    <img src={it.imageBlob} />
                </ImageBox>
            </ImageContainer>)
        } else if (it.name) {
            return (<ImageContainer>
                <ImageName>
                    {it.name}
                </ImageName>
                <LoadingBox>
                    <Loader />
                </LoadingBox>
            </ImageContainer>)
        }
    }), [filePaths])
    console.log(filePaths)
    return(
        <>
            <PageWrapper>
                <ContentContainer>
                    {id && breadCrumbComponent}
                    <Label>{t('ui.input.patientName')}</Label>
                    <DescriptionBox>
                        {caseData.patient_name}
                    </DescriptionBox>
                    <Label>{t('ui.input.complaint')}</Label>
                    <DescriptionBox>
                        {caseData.complaint}
                    </DescriptionBox>
                    {files.length ? (
                        <>
                            <Label>{t('ui.input.files')}</Label>
                            <FilesContainer>
                                {files}
                            </FilesContainer>
                        </>
                    ) : <></>}
                    {!!errorFile && (<ErrorWrapper>{errorFile}</ErrorWrapper>)}
                    {/* <Table headCells={headCells} rows={tableRows}/> */}
                    {/* <Pagination
                        pageNumber={pagination.pageNumber ?? 0}
                        pageCount={Math.ceil((totalCount ?? 0)/pagination.limit)}
                        onChange={onPageChange}
                    /> */}
                </ContentContainer>
                {/* <SaveEditFolderModal open={openFolder} onClose={handleCloseFolder} onSave={handleSave} isEdit={false} parentFolderId={id}/>
                <SaveEditCaseModal open={openCase} onClose={handleCloseCase} onSave={handleSave} isEdit={false} parentFolderId={id}/>
                {editModal}
                {deleteModal} */}
            </PageWrapper>
        </>
    )
}
