import { axiosInstance, axiosRefreshInstance } from "../index"
import { LoginArg } from "./type"

export function postLogin(data: LoginArg) {
    return axiosInstance.post(`/auth/login/`, data)
}
  
export function refreshToken(refreshToken: string) {
    return axiosRefreshInstance.post(`/auth/refresh/`, {
        refresh: refreshToken
    })
}