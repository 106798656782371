import React, {useEffect, useMemo, useState} from "react";
import OpenSeadragonViewer from "../../components/openSeaDragonViewer/OpenSeaDragonViewer";
import { useSearchParams } from "react-router-dom";
import { headerButtonActions } from "../../store/headerButton";
import { useAppActions } from "../../store/utils";

export function View() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [dzi_path, setDzi_path] = useState<string | null>();
    const [image_path, setImage_path] = useState<string | null>();
    const { removeHeaderButtonContent } = useAppActions(headerButtonActions);

    useEffect(() => {
        removeHeaderButtonContent();
    }, []);
    useEffect(() => {
        if (searchParams.get('dzi_path')) {
            setDzi_path(searchParams.get('dzi_path'))
        }
        if (searchParams.get('image_path')) {
            setImage_path(searchParams.get('image_path'))
        }
    },[])

    return(
        <>
            {
                dzi_path && image_path ? (
                    <OpenSeadragonViewer
                        dzi_path={`${process.env.REACT_APP_BASE_URL_WITHOUT_API}${dzi_path}`}
                        image_path={`${process.env.REACT_APP_BASE_URL_WITHOUT_API}${image_path}`}
                    />
                ) : (
                    <></>
                )
            }
        </>
    )
}
