import { FC, ReactNode, useMemo } from 'react';
import usePagination from '@mui/material/usePagination';

import * as S from './styled';
import { IconArrowNext16 } from '../../icons/IconArrowNext16';
import { IconArrowPrevious16 } from '../../icons/IconArrowPrevious16';

type PaginationProps = {
  /**
   * Start of counting pages for pagination
   *
   * The first page on the backend can be 0 or 1
   * @default 0
   */
  countingStartsFrom?: 0 | 1;
  /**
   * If `true`, the whole component is disabled
   * @default false
   */
  disabled?: boolean;
  /**
   * The current page
   */
  pageNumber: number;
  /**
   * The total number of pages
   * @default 1
   */
  pageCount: number | undefined;
  /**
   * Callback fired when the page is changed
   * @param {number} page The selected page
   */
  onChange: (page: number) => void;
};

export const Pagination: FC<PaginationProps> = (props) => {
  const {
    countingStartsFrom = 0,
    disabled = false,
    pageNumber,
    pageCount = 1,
    onChange,
  } = props;

  // Variables for correct calculation of pagination at different start of counting pages
  const page = countingStartsFrom === 0 ? pageNumber + 1 : pageNumber;
  const newPageDelta = countingStartsFrom === 0 ? -1 : 0;

  const hasPages = Boolean(pageCount !== 0);

  const { items } = usePagination({
    // default props
    boundaryCount: 1,
    defaultPage: 1,
    hideNextButton: false,
    hidePrevButton: false,
    showFirstButton: false,
    showLastButton: false,
    siblingCount: 1,

    //

    disabled,
    page: page,
    count: pageCount,
    onChange: (e, p) => onChange(p + newPageDelta),
  });

  const pages = useMemo(() => {
    return items
      .filter((it) => it.type !== 'next' && it.type !== 'previous')
      .map((it, index) => {
        const { type, page, selected, ...rest } = it;

        let children: ReactNode;
        switch (type) {
          case 'page': {
            children = (
              <S.PaginationItem key={index}>
                <S.PaginationButton
                  variant={'outlined'}
                  // color="secondary"
                  body={String(page)}
                  {...rest}
                  $selected={selected}
                />
              </S.PaginationItem>
            );
            break;
          }

          case 'start-ellipsis':
          case 'end-ellipsis': {
            children = (
              <S.PaginationItem key={index}>
                <S.PaginationEllipsis>...</S.PaginationEllipsis>
              </S.PaginationItem>
            );
            break;
          }
        }

        return children;
      });
  }, [items]);

  const arrows = useMemo(() => {
    const nextButton = items.find((it) => it.type === 'next');
    const previousButton = items.find((it) => it.type === 'previous');

    return (
      <>
        <S.PaginationItem>
          <S.PaginationArrows
            icon={<IconArrowPrevious16 />}
            disabled={previousButton?.disabled}
            $disabled={previousButton?.disabled}
            onClick={(e: any) => previousButton?.onClick(e)}
          />
        </S.PaginationItem>
        <S.PaginationPagesContainer>{pages}</S.PaginationPagesContainer>
        <S.PaginationItem>
          <S.PaginationArrows
            icon={<IconArrowNext16 />}
            disabled={nextButton?.disabled}
            $disabled={nextButton?.disabled}
            onClick={(e: any) => nextButton?.onClick(e)}
          />
        </S.PaginationItem>
      </>
    );
  }, [items]);

  return (
    <S.PaginationContainer>
      {hasPages ? (
        <>
          {/* <S.PagesWrapper>{pages}</S.PagesWrapper> */}
          <S.ArrowsWrapper>{arrows}</S.ArrowsWrapper>
        </>
      ) : null}
    </S.PaginationContainer>
  );
};
