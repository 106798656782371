import styled from "styled-components";
import Modal from "@mui/material/Modal";
import {Box} from "@mui/material";

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const StyledBox = styled(Box)`
  position: relative;
  width: 431px;
  background-color: #F5F7FA;
  border-radius: 10px;
  border: 1px solid #C7CFD6;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`
export const StyledTitle = styled.h3`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: #0A0A0A;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const Cross = styled.div`
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
`
