import { FC } from 'react';

export const IconFolder: FC = () => {
  return (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 20H20C20.5304 20 20.6249 19.3776 21 19.0041C21.3751 18.6305 22 18.5364 22 18.0081V8.04879C22 7.52052 21.3751 7.42641 21 7.05286C20.6249 6.67931 20.5304 6.05692 20 6.05692H12C11.6706 6.05523 11.2896 6.21329 11 6.05692C10.7104 5.90055 10.1812 5.33499 10 5.06099V4.06506C9.81882 3.79105 9.28959 3.22549 9 3.06912C8.71041 2.91275 8.32941 3.07082 8 3.06912H4C3.46957 3.06912 3.37507 3.69151 3 4.06506C2.62493 4.4386 2 4.53271 2 5.06099V18.0081C2 19.1037 2.9 20 4 20Z" stroke="#0A0A0A" />
    <path d="M2 10H22" stroke="#0A0A0A" />
  </svg>
  
  );
};
