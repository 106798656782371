import React, {useEffect, useMemo, useState} from "react";
import { useTranslation } from "react-i18next";
import {ContentContainer, PageWrapper, TitleWrapper} from "./style";
import {WrapperTitle} from "../../components/title/Title";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import {HeadCell, StyledTableRow, Table, TableCell} from "../../components/Table";
import {useAppActions} from "../../store/utils";
import {headerButtonActions} from "../../store/headerButton";
import {getUsers} from "../../axios/staff/staffApi";
import {Button} from "../../components/button";
import {IconPlus} from "../../icons/IconPlus";
import {IconButton} from "../../components/IconButton";
import {IconEdit} from "../../icons/IconEdit";
import {IconCross} from "../../icons/IconCross";
import {deleteRole, getRoles} from "../../axios/role/roleApi";
import {PermissionItem} from "../../axios/role/type";
import SaveEditRoleModal from "../../components/modals/saveEditRoleModal/SaveEditRoleModal";
import ConfirmModal from "../../components/modals/confirmModal/ConfirmModal";
import { usePagination } from "../../hooks/usePagination";
import { Pagination } from "../../components/Pagination";

export function Roles() {
	const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [rolesList, setRolesList] = useState([]);
    const [editOpen, setEditOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [totalCount, setTotalCount] = useState();
    const [deleteError, setDeleteError] = useState<{title: string, body: any} | undefined>();
    const [selectRole, setSelectRole] = useState<number>();
    const { pagination, onPageChange, resetPagination } = usePagination({
        offset: 0,
        limit: 6,
        pageNumber: 0,
      });
    const { setHeaderButtonComponent } = useAppActions(headerButtonActions);

    useEffect(() => {
        const offset = (pagination.pageNumber + 1) != 1 
            ? pagination.limit * (pagination?.pageNumber ?? 1)
            : 0;
        getRoles(offset, pagination.limit).then((data) => {
            setRolesList(data.data.results);
            setTotalCount(data.data.count);
        });
    }, [pagination.pageNumber])

    useEffect(() => {
        if (Math.ceil((totalCount ?? 0)/pagination.limit) < (pagination.pageNumber + 1) && ((pagination.pageNumber + 1) > 1)) {
            onPageChange(pagination.pageNumber - 1)
        }
    }, [totalCount, pagination.pageNumber]);

    useEffect(() => {
        setHeaderButtonComponent(<Button onClick={handleOpen} startIcon={<IconPlus />} variant={"contained"} body={t('roles.createRole')} />)
    }, []);

    const handleSaveRole = () => {
        const offset = (pagination.pageNumber + 1) != 1 
            ? pagination.limit * (pagination?.pageNumber ?? 1)
            : 0;
        getRoles(offset, pagination.limit).then((data) => {
            setRolesList(data.data.results);
            setTotalCount(data.data.count);
        });
        setOpen(false);
        setEditOpen(false);
        setDeleteOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleEditOpen = (role: number) => {
        setSelectRole(role);
        setEditOpen(true);
    };
    const handleEditClose = () => {
        setEditOpen(false);
    };
    const handleDeleteOpen = (role: number) => {
        setSelectRole(role);
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        setDeleteError(undefined);
        setSelectRole(undefined);
    };

    const handleDeleteRole = () => {
        deleteRole(selectRole ?? 0).then(() => {
            handleSaveRole();
        }).catch((error) => setDeleteError({
                title: t('ui.modal.title.deleteRoleErrorTitle'),
                body: error.response.data.staffs.map((it: any) => {
                    return (
                        <div>{`${it.first_name} ${it.last_name}`}</div>
                    )
                })
            })
        );
    }
    const headCells: HeadCell[] = [
        {
            disablePadding: true,
            label: t('ui.table.title'),
            width: '400px',
            numeric: false,
            id: '1',
        },
        {
            disablePadding: true,
            label: t('ui.table.createFolders'),
            numeric: false,
            id: '2',
        },
        {
            disablePadding: true,
            label: t('ui.table.createCases'),
            numeric: false,
            id: '3',
        },
        {
            disablePadding: true,
            label: t('ui.table.editFolders'),
            numeric: false,
            id: '4',
        },
        {
            disablePadding: true,
            label: t('ui.table.editCases'),
            numeric: false,
            id: '5',
        },
        {
            disablePadding: true,
            label: t('ui.table.downloadFolders'),
            numeric: false,
            id: '6',
        }
    ];
    const [checked, setChecked] = useState(false);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const checkPermission = (permissions: PermissionItem[], permissionName:string) => {
        return permissions.some((permission) => permission.name === permissionName);
    };
    
    const editModal = useMemo(() => {
        if (selectRole) {
            return (
                <SaveEditRoleModal open={editOpen} onClose={handleEditClose} onSave={handleSaveRole} isEdit={true} roleId={selectRole} />
            )
        }
    }, [editOpen,selectRole]);

    const deleteModal = useMemo(() => {
        if (selectRole) {
            return (
                <ConfirmModal error={deleteError} open={deleteOpen} onClose={handleDeleteClose} onSubmit={handleDeleteRole} submitButtonText={t('ui.button.delete')} title={t('ui.modal.title.confirmDeleteRole')}/>
            )
        }
    }, [deleteOpen,selectRole, deleteError]);

    const tableRows = useMemo(() => {
        return rolesList?.map((row: any) => (
            <StyledTableRow key={row.id} >
                <TableCell align="left">{row.name}</TableCell>
                {["Создание папок", "Создание случаев", "Редактирование папок", "Редактирование случаев", "Загрузка файлов"].map((permissionName) => (
                    <TableCell key={permissionName} align="left">
                        {checkPermission(row.permissions, permissionName) ? (
                            <span style={{color: "#0D8B12"}}>Разрешено</span>
                        ) : (
                            <span style={{color: "#AD2A2A"}}>Запрещено</span>
                        )}
                    </TableCell>
                ))}
                <TableCell align="right" width={'150px'}>
                    <div>
                        <IconButton icon={<IconEdit />} onClick={() => handleEditOpen(row.id)} />
                        <IconButton icon={<IconCross />} onClick={() => handleDeleteOpen(row.id)} />
                    </div>
                </TableCell>
            </StyledTableRow>
        ));
    }, [rolesList]);

    return(
        <>
            <PageWrapper>
                {/*<TitleWrapper>*/}
                {/*        <WrapperTitle>Роли</WrapperTitle>*/}
                {/*        <Breadcrumb>Пользователи / sss</Breadcrumb>*/}
                {/*</TitleWrapper>*/}

                <ContentContainer>
                    <Table headCells={headCells} rows={tableRows}/>
                    <Pagination
                        pageNumber={pagination.pageNumber ?? 0}
                        pageCount={Math.ceil((totalCount ?? 0)/pagination.limit)}
                        onChange={onPageChange}
                    />
                </ContentContainer>
                <SaveEditRoleModal open={open} onClose={handleClose} onSave={handleSaveRole} isEdit={false} />
                {editModal}
                {deleteModal}
            </PageWrapper>
        </>
    )
}
