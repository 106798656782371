import React,{useEffect, useState, useRef} from 'react';
import OpenSeadragon from 'openseadragon';
import { IconButton } from '../IconButton';
import { IconPlus } from '../../icons/IconPlus';
import { IconMinus } from '../../icons/IconMinus';
import { Button } from '../button';

const OpenSeadragonViewer = ({dzi_path, image_path}: {dzi_path: string, image_path: string}) => {
  const viewerRef = useRef(null);
  const access = localStorage.getItem('access');
  useEffect(() => {
    const viewer = OpenSeadragon({
      id: 'viewer',
      prefixUrl: image_path,
      tileSources: dzi_path,
      zoomInButton:   "zoom-in",
      zoomOutButton:  "zoom-out",
      homeButton:     "home",
      fullPageButton: "full-page",
      loadTilesWithAjax: true,
      ajaxWithCredentials: true,
      ajaxHeaders: {
        'Authorization': `Bearer ${access}`
      }
    });
    const originalMakeAjaxRequest = OpenSeadragon.makeAjaxRequest;

    OpenSeadragon.makeAjaxRequest = function (options: any) {
      options = options || {};
    
      options.headers = options.headers || {};
      options.headers['Authorization'] = `Bearer ${access}`;

      return originalMakeAjaxRequest(options);
    };
    if (viewer) {
        // @ts-ignore
        viewerRef.current = viewer;
    }
    return () => {
        // @ts-ignore
      viewerRef.current?.destroy();
    };
  }, []);
  return (
    <>
      <div style={{display: 'flex',  gap: '6px'}}>
        <Button id={'zoom-in'} body={'Увеличить'}/>
        <Button id={'zoom-out'} body={'Уменьшить'}/>
        <Button id={'home'} body={'Сбросить'}/>
        <Button id={'full-page'} body={'Во весь экран'}/>
      </div>
      <div id="viewer" style={{ width: '100%', height: '100%' }} />
    </>
  );
};
export default OpenSeadragonViewer;