import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const SideBarContainer = styled.div`
  position: relative;
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  overflow: hidden;
  height: 100vh;

  border-right: 1px solid #C7CFD6;
  width: 250px;
  min-width: 250px;
  //padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ExpiredSideBarContainer = styled.div`

  width: 116px;
  height: 100%;
  background-color: #F5F7FA;

  border-left: 1px solid #C7CFD6;
  //padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const LogoContainer = styled.div`
  font-size: 36px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
  color:  #475F7B;

  height: 28px;
  padding: 32px 0;
`;


export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;

  overflow: hidden auto;
  padding-top: 28px;
`;

export const BottomContent = styled.div`
  position: absolute;
  left: 26px;
  bottom: 38px;

  p {
    
  }
`;


export const Menu = styled.div`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 0 26px 0 20px;
  flex-grow: 1;
`;

export const MenuItem = styled.div`
  display: block;
`;

const baseMenuLinkStyles = css`
  display: flex;
  align-items: center;
  gap: 12px;

  height: 32px;
  // width: 144px;
  padding: 8px 16px 8px 16px;
  border: 1px solid transparent;
  border-radius: 4px;
  

  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color:  #475F7B;
  //styleName: M3/label/large;
  // font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  text-decoration: none;

  transition: all 0.3s ease-in;

  path {
    fill: #0A0A0A;
    stroke: #red;
  }
`;

export const MenuLink = styled(NavLink)`
  ${baseMenuLinkStyles};

  &.active {
    //background: #E7EDF3;
    background: #006666;
    color: #FEFEFE;
    path {
      fill: #FEFEFE;
    }
    // box-shadow: black;
    // background: rgba(39, 41, 55, 0.04);
    // padding: 4px, 8px, 4px, 8px;
  }

  &:hover {
    background: #transparent;
    border: 1px solid #006666
    //background: green;
  }
}

}
`;

export const MenuLinkExternal = styled.a`
  ${baseMenuLinkStyles};
`;
