import { createSlice } from '@reduxjs/toolkit';

type Profile = any;

type UserState = {
  profile: Profile | null;
};

const initialState: UserState = {
  profile: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (build) => {
    // build.addMatcher(mainEndpoints.endpoints.getProfileInfo.matchFulfilled, (state, action) => {
    //   state.profile = action.payload;
    // });
  },
});

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;
