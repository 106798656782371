import styled, { css } from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;
  height: 100px;
  justify-content: space-between;
  //padding: 20px;
  display: flex;
  flex-direction: row;
  gap: 32px;
`;
export const HeaderContainerContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const HeaderDateContainer = styled.div`
  color: #475F7B;
  padding-left: 20px;
  top: -20px;
  position: absolute;
`

export const HeaderTextContainer = styled.div`
  padding-left: 20px;
  font-family: Rubik;
  font-size: 32px;
  font-weight: 400;
  line-height: 100px;
  letter-spacing: 0px;
  text-align: left;
  color: #475F7B;
  height: 100%;
`;

export const HeaderButtonConainer = styled.div`
  padding-right: 20px;
  line-height: 100px;
  text-align: left;
  color: #475F7B;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
`;
