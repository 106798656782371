import styled from "styled-components";

export const PageWrapper = styled.div`

`;
export const TitleWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  height: 32px;
  align-items: flex-end;
`;

export const ContentContainer = styled.div<{ $gap?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap:  10px;
  width: 100%;
  margin: 0;
  padding-bottom: 5px;
`;

export const FolderNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const IconFolderContainer = styled.div`
  width: 18px;
  height: 18px;
`;