import React, { ReactElement, MouseEvent } from 'react';
import {
  Box,
  Paper,
  TableBody,
  TableContainer,
  TableRow,
  TableSortLabel,
  CircularProgress,
} from '@mui/material';

import * as S from './styled';
import type { HeadCell, TableProps } from './types';
import { StyledPagination } from './styled';

type Order = 'asc' | 'desc';

type EnhancedTableProps = {
  order?: Order;
  orderBy?: string;
  headCells?: (HeadCell | undefined)[];
};

export const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { order, orderBy, headCells } = props;

  return (
    <>
      <S.THead>
        <TableRow>
          {headCells?.map((headCell) => {
            if (!headCell) {
              return null;
            }

            return (
              <S.TableHeaderCell
                $width={headCell.width}
                key={headCell.id}
                align={headCell.numeric ? 'center' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  hideSortIcon
                  direction={orderBy === headCell.id ? order : 'asc'}
                >
                  {/*{headCell.showSortIcon ? <IconDropperDown /> : <></>}*/}
                  {headCell.label}
                </TableSortLabel>
              </S.TableHeaderCell>
            );
          })}
        </TableRow>
      </S.THead>
    </>
  );
};

export const Table = (props: TableProps): ReactElement => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage ?? 25);

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    props.onPageChange && props.onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rows = +event.target.value;
    setRowsPerPage(rows);
    setPage(0);
    props.onRowsPerPageChange && props.onRowsPerPageChange(rows);
  };
  //TODO overflow
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', maxHeight: 'calc(100vh - 230px)', overflowY: 'hidden', mb: 2 }}>
          <TableContainer>
            <S.TableStyled stickyHeader>
              <EnhancedTableHead headCells={props.headCells} />
              {/* {props.isLoading ? (
                <ProgressContainer>
                  <CircularProgress color="secondary" />
                </ProgressContainer>
              ) : (
                <TableBody>{props.rows}</TableBody>
              )} */}
              <TableBody>{props.rows}</TableBody>
            </S.TableStyled>
          </TableContainer>
          {/* {props.withPagination && (
            <StyledPagination
              count={props.totalRows || props.rows?.length || 0}
              onChange={props.onChangePagination}
              // rowsPerPage={rowsPerPage}
              page={props.pageNumber}
              // onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )} */}
        </Paper>
      </Box>
    </>
  );
};
