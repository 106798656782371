import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n';
import "@fontsource/rubik";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/400-italic.css";
import "@fontsource/open-sans";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/400-italic.css";
import "@fontsource/bebas-neue";
import "@fontsource/bebas-neue/400.css";
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import { store } from './store';
import { ThemeProvider, createTheme } from '@mui/material';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
    palette: {
      primary: {
        main: '#006666',
      },
    },
  });

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </BrowserRouter>
    </Provider>

);

