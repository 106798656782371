import React, { FC, ReactNode, useEffect, useState } from 'react';

import { Button } from '../../button';
import { t } from 'i18next';
import {
  Cross,
  InputContainer,
  StyledBox,
  StyledModal,
  StyledTitle,
  StyledForm,
} from './styled';
import { Input } from '../../customInput';
import { getAllRoles, getRoles } from '../../../axios/role/roleApi';
import {
  PermissionItem,
  RolesResponse,
  UserArg,
} from '../../../axios/role/type';
import { MenuItem, colors } from '@mui/material';
import {
  addUser,
  getAllUsers,
  getUserbyId,
  getUsers,
  updateUser,
} from '../../../axios/staff/staffApi';
import { Select } from '../../Select';
import { Radio, RadioGroup } from '../../RadioGroup';
import {
  postCreateFolderInFolderInRoot,
  postCreateFolderInRoot,
} from '../../../axios/folder/folderApi';
import { Folder } from '../../../axios/folder/type';
import { RadioRowLabelContainer } from '../../RadioGroup/styled';
import {
  getCase,
  getFile,
  postCreateCase,
  updateCase,
} from '../../../axios/case/caseApi';
import { CaseDTO } from '../../../axios/case/type';
import { IconUnlock } from '../../../icons/IconUnock';
import { SettinPermissionContainer } from '../saveEditFolderModal/styled';
import { Label, MultiSelect } from '../../Select/styled';
import { IconDropperDown } from '../../../icons/IconDropperDown';
import { hasPermision } from '../../../utils/utils';
import { Link } from '../../link';
import { Download } from '../../download/Download';
import { useNavigate } from 'react-router-dom';
import { deleteFile } from '../../../axios/file/fileApi';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  isEdit: boolean;
  caseId?: number;
  parentFolderId?: string;
  cancelCondition: boolean;
  handleFileChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number | undefined
  ) => Promise<void>;
}

export enum PermissionType {
  PRIVATE = 'PRIVETE',
  OPEN = 'OPEN',
  BLACK_LIST = 'BLACK_LIST',
  WHITE_LIST = 'WHITE_LIST',
}

const SaveEditCaseModal: FC<ModalProps> = ({
  open,
  onClose,
  onSave,
  isEdit,
  caseId,
  parentFolderId,
  cancelCondition,
  handleFileChange,
}) => {
  const navigate = useNavigate();

  const [caseData, setCaseData] = useState<CaseDTO>({} as CaseDTO);
  const [users, setUsers] = useState<any[]>([]);
  const [permissionType, setPermissionType] = useState<PermissionType>(
    PermissionType.OPEN
  );
  const [errorFields, setErrorFields] = useState<
    { field: string; message: any }[]
  >([]);
  const [errorFileName, setErrorFileName] = useState({ show: false, text: '' });
  const flag = useSelector((state: RootState) => state.fileUpload.flag);
  const alert = useSelector((state: RootState) => state.fileUpload.alert);
  const alertText = useSelector(
    (state: RootState) => state.fileUpload.alertText
  );

  useEffect(() => {
    setCaseData({} as CaseDTO);
    setPermissionType(PermissionType.OPEN);
    setErrorFields([]);
    if (open) {
      getAllUsers().then((data) => {
        const filteredData = data.data.results
          .filter((it: any) => !it.is_admin)
          .map((item: UserArg) => ({
            id: item.id,
            name: `${item.first_name} ${item.last_name}`,
          }));
        setUsers(filteredData);
      });
    }
  }, [open]);

  useEffect(() => {
    if (alert && alertText) {
      showErrorFile(alertText);
    }
  }, [alert, alertText]);

  useEffect(() => {
    if (isEdit && users && open) {
      getCase(caseId ?? 0).then(({ data }) => {
        setPermissionType(
          data.black_list.length
            ? PermissionType.BLACK_LIST
            : data.white_list.length
            ? PermissionType.WHITE_LIST
            : data.visible
            ? PermissionType.OPEN
            : PermissionType.PRIVATE
        );
        const userList = data?.white_list.length
          ? data.white_list?.map((item: any) => {
              return {
                id: item.id,
                name: `${item.first_name} ${item.last_name}`,
              };
            })
          : data.black_list?.map((item: any) => {
              return {
                id: item.id,
                name: `${item.first_name} ${item.last_name}`,
              };
            });
        setCaseData({
          ...data,
          black_list: userList,
          white_list: userList,
        });
      });
    }
  }, [caseId, users]);

  useEffect(() => {
    if (flag && caseId) {
      getCase(caseId ?? 0).then(({ data }) => {
        setCaseData((prevState) => {
          return {
            ...prevState,
            files: data.files,
          };
        });
      });
    }
  }, [flag, caseId]);

  const handleEditFields = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    setCaseData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const errorHandler = (error: any) => {
    const errorFields = Object.entries(error.response.data)?.map(
      ([field, messages]) => ({
        field,
        // @ts-ignore
        message: messages[0],
      })
    );
    setErrorFields(errorFields);
  };

  const onHandleSubmit = (e: any) => {
    e.preventDefault();
    const data: CaseDTO =
      permissionType == PermissionType.WHITE_LIST
        ? {
            name: caseData.name,
            white_list: caseData.white_list?.map((it) => it.id),
            patient_name: caseData.patient_name,
            complaint: caseData.complaint,
          }
        : permissionType == PermissionType.BLACK_LIST
        ? {
            name: caseData.name,
            black_list: caseData.black_list?.map((it) => it.id),
            patient_name: caseData.patient_name,
            complaint: caseData.complaint,
          }
        : {
            name: caseData.name,
            patient_name: caseData.patient_name,
            complaint: caseData.complaint,
            visible: permissionType === PermissionType.OPEN ? true : false,
          };
    if (parentFolderId) {
      data.in_folder = parentFolderId;
    }

    if (!isEdit) {
      postCreateCase(data)
        .then(() => {
          onSave();
        })
        .catch((error) => {
          errorHandler(error);
        });
    } else if (caseId) {
      if (permissionType === PermissionType.BLACK_LIST) {
        data.white_list = [];
      }
      if (permissionType === PermissionType.WHITE_LIST) {
        data.black_list = [];
      }
      if (
        permissionType === PermissionType.OPEN ||
        permissionType === PermissionType.PRIVATE
      ) {
        data.black_list = [];
        data.white_list = [];
      }
      updateCase(caseId, data)
        .then(() => {
          onSave();
        })
        .catch((error) => {
          errorHandler(error);
        });
    }
  };
  const onSelect = (selectedList: any, selectedItem: any) => {
    setCaseData((prevState) => {
      return {
        ...prevState,
        black_list: selectedList,
        white_list: selectedList,
      };
    });
  };

  const showErrorFile = (text: string) => {
    setErrorFileName({
      show: true,
      text: text,
    });

    setTimeout(() => {
      setErrorFileName({
        show: false,
        text: '',
      });
    }, 5000);
  };

  const onRemove = (selectedList: any, removedItem: any) => {
    setCaseData((prevState) => {
      return {
        ...prevState,
        black_list: selectedList,
        white_list: selectedList,
      };
    });
  };
  const handleDeleteFile = (id: number) => {
    deleteFile(id).then(() => {
      getCase(caseId ?? 0).then(({ data }) => {
        setCaseData((prevState) => {
          return {
            ...prevState,
            files: data.files,
          };
        });
      });
    });
  };

  const recipientTypeRadios: Radio[] = [
    {
      value: PermissionType.OPEN,
      label: (
        <RadioRowLabelContainer>
          {t('ui.modal.permisыion.open')}{' '}
          <span>{t('ui.modal.permisыion.openSecond')}</span>
        </RadioRowLabelContainer>
      ),
    },
    {
      value: PermissionType.PRIVATE,
      label: (
        <RadioRowLabelContainer>
          {t('ui.modal.permisыion.private')}{' '}
          <span>{t('ui.modal.permisыion.privateSecond')}</span>
        </RadioRowLabelContainer>
      ),
    },
    {
      value: PermissionType.WHITE_LIST,
      label: (
        <RadioRowLabelContainer>
          {t('ui.modal.permisыion.whiteList')}{' '}
          <span>{t('ui.modal.permisыion.whiteListSecond')}</span>
        </RadioRowLabelContainer>
      ),
    },
    {
      value: PermissionType.BLACK_LIST,
      label: (
        <RadioRowLabelContainer>
          {t('ui.modal.permisыion.blackList')}{' '}
          <span>{t('ui.modal.permisыion.blackListSecond')}</span>
        </RadioRowLabelContainer>
      ),
    },
  ];

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      <StyledBox>
        <Cross onClick={onClose}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
          >
            <path
              d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z'
              fill='#475F7B'
            />
          </svg>
        </Cross>
        <StyledTitle>
          {isEdit
            ? t('ui.modal.title.editCase')
            : t('ui.modal.title.createCase')}
        </StyledTitle>
        <StyledForm onSubmit={onHandleSubmit}>
          <InputContainer>
            <Input
              name='name'
              value={caseData?.name}
              onChange={handleEditFields}
              disabled={!hasPermision(4) && isEdit}
              label={t('ui.input.name')}
              placeholder={t('ui.input.userName')}
              errors={errorFields}
            ></Input>
            <Input
              name='patient_name'
              disabled={!hasPermision(4) && isEdit}
              value={caseData?.patient_name}
              onChange={handleEditFields}
              label={t('ui.input.patientName')}
              placeholder={t('ui.input.patientName')}
              errors={errorFields}
            ></Input>
            <Input
              name='complaint'
              disabled={!hasPermision(4) && isEdit}
              value={caseData?.complaint}
              onChange={handleEditFields}
              label={t('ui.input.complaint')}
              placeholder={t('ui.input.complaint')}
              errors={errorFields}
            ></Input>
            {isEdit && (
              <Download
                fileError={errorFileName}
                showErrorFile={showErrorFile}
                onDeleteFileById={handleDeleteFile}
                label={t('ui.input.files')}
                cancelCondition={cancelCondition}
                id={caseId}
                files={caseData.files}
                handleFileChange={handleFileChange}
              ></Download>
            )}

            <SettinPermissionContainer>
              <IconUnlock />
              {t('ui.modal.title.permissionSetting')}
            </SettinPermissionContainer>
            {(permissionType == PermissionType.BLACK_LIST ||
              permissionType == PermissionType.WHITE_LIST) && (
              <div>
                <Label>
                  {!isEdit
                    ? t('ui.input.selectUsers')
                    : permissionType == PermissionType.BLACK_LIST
                    ? t('ui.input.selectUsersBlackList')
                    : t('ui.input.selectUsersWhiteList')}
                </Label>
                <MultiSelect
                  options={users?.map((role: any) => {
                    return { id: role.id, name: role.name };
                  })} // Options to display in the dropdown
                  selectedValues={
                    permissionType == PermissionType.BLACK_LIST
                      ? caseData?.black_list ?? []
                      : caseData?.white_list ?? []
                  } // Preselected value to persist in dropdown
                  onSelect={onSelect} // Function will trigger on select event
                  onRemove={onRemove} // Function will trigger on remove event
                  displayValue='name' // Property name to display in the dropdown options
                  closeIcon={'cancel'}
                  placeholder={''}
                  disable={!hasPermision(4) && isEdit}
                  showArrow={true}
                  customArrow={<IconDropperDown />}
                  emptyRecordMsg={'Пользователи не найдены'}
                />
              </div>
            )}
            <RadioGroup
              disabled={!hasPermision(4) && isEdit}
              value={permissionType}
              radios={recipientTypeRadios}
              onChange={(e) => setPermissionType(e as PermissionType)}
            />
          </InputContainer>
          {isEdit && (
            <Link
              onClick={() =>
                navigator.clipboard.writeText(
                  `${window.location.origin}/folders${
                    parentFolderId ? `/${parentFolderId}` : ''
                  }?case=${caseId}`
                )
              }
              text={'Копировать ссылку'}
            ></Link>
          )}
          {hasPermision(2) && !isEdit && (
            <Button
              type={'submit'}
              variant='contained'
              body={t('ui.button.create')}
            ></Button>
          )}
          {hasPermision(4) && isEdit && (
            <Button
              type={'submit'}
              variant='contained'
              body={t('ui.button.update')}
            ></Button>
          )}
        </StyledForm>
      </StyledBox>
    </StyledModal>
  );
};

export default SaveEditCaseModal;
