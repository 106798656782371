import { FC } from 'react';

export const IconFolderTable: FC = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.5 15H15.5C15.8978 15 15.9687 14.5241 16.25 14.2384C16.5313 13.9527 17 13.8808 17 13.4768V5.86084C17 5.45687 16.5313 5.3849 16.25 5.09924C15.9687 4.81359 15.8978 4.33765 15.5 4.33765H9.5C9.25294 4.33635 8.9672 4.45723 8.75 4.33765C8.5328 4.21807 8.13588 3.78558 8 3.57605V2.81445C7.86412 2.60492 7.4672 2.17244 7.25 2.05286C7.0328 1.93328 6.74706 2.05416 6.5 2.05286H3.5C3.10218 2.05286 3.0313 2.5288 2.75 2.81445C2.4687 3.10011 2 3.17208 2 3.57605V13.4768C2 14.3146 2.675 15 3.5 15Z" stroke="#0A0A0A" />
      <path d="M2 8H17" stroke="#0A0A0A" />
    </svg>
  );
};
