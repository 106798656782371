import styled from 'styled-components';
import {
  Table as MuiTable,
  TableCell as MuiTableCell,
  TableHead,
  TablePagination,
  TableRow as MuiTableRow,
  Pagination,
} from '@mui/material';


export const WrapperText = styled.div<{ $maxWidth: string }>`
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  max-width: ${({ $maxWidth }) => $maxWidth};
`;

export const TableRow = styled(MuiTableRow)`
  :hover {
    // background: whitesmoke;

    td:first-child {
      padding-left: 40px;
      border-radius: 999rem 0 0 999rem;
    }

    td:last-child {
      border-radius: 0 999rem 999rem 0;
    }
  }

  border-bottom: none;
`;

export const TableStyled = styled(MuiTable)`
  box-shadow: 0px 4px 4px 0px #00000040;
  // background: #C7CFD6;
  // padding: 20px;
  max-height: 115px;
  overflow: scroll;

  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: black;

  .MuiDataGrid-columnHeaderDraggableContainer {
    width: 100%;
    font-weight: 500;
  }

  .MuiTableCell-head {
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 30px;
    height: 80px;
    color: #727E8C;
    border: none;
    background-color: none;
  }

  .MuiTableCell-body {
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    padding: 4px 16px;
    padding-left: 0px;
    color: #727E8C;
    border: none;
  }

  .MuiDataGrid-cell {
    font-weight: 400;
  }

  .MuiDataGrid-icon {
    left: 0;
  }
`;

export const Label = styled.span`
  display: inline-block;
  margin-bottom: 4px;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 20px;
  color: gray;
`;

export const THead = styled(TableHead)`
  border-bottom: none;
`;

export const TableHeaderCell = styled(MuiTableCell)<{ $width?: string }>`
  width: ${({ $width }) => $width};
  padding: 0 8px;
  border-bottom: none;
  
  &:first-child {
    padding-left: 40px;
    // border-radius: 999rem 0 0 999rem;
  }
`;


export const StyledPagination = styled(Pagination)`
  font-size: 12px;
  display: flex;
  justify-content: end;

  .MuiTablePagination-toolbar {
    min-height: 35px;
  }

  .MuiTablePagination-selectLabel,
  .MuiTablePagination-displayedRows {
    font-size: 12px;
  }

  .MuiPagination-ul {
    border: 1px solid #C7CFD6;
    border-radius: 4px;
  }

  .Mui-selected {
    background-color: #5A8DEE !important;
    color: #fff;
  }
`;


export const TableCell = styled(MuiTableCell)`
  border-bottom: none;
  &:first-child {
    padding-left: 40px;
    // border-radius: 999rem 0 0 999rem;
  }

`;
export const StyledTableRow = styled(TableRow)`
  &:last-child th {
    border: 0;
  }

  height: 80px;

  &:hover {
    cursor: pointer;
    background-color: #EEF6F6;
  }

  .full {
    width: 100%;
    .MuiInput-input {
      font-size: 14px;
    }
  }
`;
