import { FC, ReactElement } from 'react';
import {
  FormControlLabelProps as MuiFormControlLabelProps,
  RadioProps as MuiRadioProps,
} from '@mui/material';

import * as S from './styled';

//region Types
type RadioGroupProps = {
  radios: Radio[];
  showErrorMessage?: boolean;
  row?: boolean;
  disabled?: boolean;
  value?: React.ReactNode;
  onChange: (value: string) => void;
};
type RadioControlLabelProps = Omit<MuiFormControlLabelProps, 'control'> & {
  value: string;
};
export type Radio = RadioControlLabelProps;
type RadioProps = MuiRadioProps;
//endregion

export const RadioGroup = (
  props: RadioGroupProps
): ReactElement => {
  const { radios, value, showErrorMessage = true, disabled = false, row = false, onChange } = props;

  // const hasError = errors ? Boolean(getErrorByFieldName(errors, control.name)) : false;

  const radiosElements = radios.map((it) => {
    return <RadioControlLabel disabled={disabled} key={it.value} {...it} />;
  });

  return (
    <>
      <S.RadioGroup row={row} value={value ?? ''} onChange={(event, value) => onChange(value)}>
        {radiosElements}
      </S.RadioGroup>

      {/* {showErrorMessage && hasError && <ErrorMessage fieldName={control.name} errors={errors} />} */}
    </>
  );
};

const RadioControlLabel: FC<RadioControlLabelProps> = (props) => {
  return <S.RadioControlLabel {...props} control={<Radio disableRipple disabled={props.disabled} />} labelPlacement="end" />;
};

const Radio: FC<RadioProps> = (props) => {
  return <S.Radio {...props} disabled={props.disabled} icon={<S.RadioIcon />} checkedIcon={<S.RadioIconChecked />} />;
};
