import { FC } from "react";
import { Button } from "../button";
import { BottomContent, LogoContainer, Menu, MenuItem, MenuLink, MenuLinkExternal, SideBarContainer } from "./styled";
import { menu } from "./menu";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import Logo from "../../icons/logo.png";
import { getCurrentUser } from "../../axios/staff/staffApi";

export type MenuModel = {
    name: string;
    icon: any;
    path: string;
    isExternal?: boolean;
    permission?: string;
    isAdmin?: boolean;
    secondPath?: 'IS_ADMIN' | 'IS_NO_ADMIN';
  };

export const SideBar: FC<any> = ({ closeMenu }) => {

    const menuItemClickHandler = () => {
        if (closeMenu) {
          closeMenu();
        }
      };

  const navigation = useNavigate();    
  const {t} = useTranslation();
  const currentUserIsAdmin = localStorage.getItem('is_admin')
  const siderBarMenu = menu.map((link: any) => {
    const { name, icon: Icon, isExternal, path, isAdmin, secondPath } = link;
    if (isAdmin && currentUserIsAdmin == 'false') {
      return;
    }

    const linkContent = (
      <>
      <div style={{marginLeft: '4px', marginTop: '4px'}}>
        <Icon />
      </div>
        {t(`menus.${name}` as unknown as TemplateStringsArray)}
      </>
    );
    
    const activeTab = currentUserIsAdmin == 'true' ? 'IS_ADMIN' : 'IS_NO_ADMIN';

    return (
      <MenuItem key={name}>
        {isExternal ? (
          <MenuLinkExternal
            href={path}
            // target="_blank"
            rel="noopener noreferrer"
          >
            {linkContent}
          </MenuLinkExternal>
        ) : (
          <MenuLink to={path} className={`${!secondPath 
              ? '' 
              : secondPath == activeTab
                ?  window.location.pathname == '/' ? 'active' : ''
                :  ''}`} onClick={menuItemClickHandler}>
            {linkContent}
          </MenuLink>
        )}
      </MenuItem>
    );
  });

  return (
    <SideBarContainer>
      <LogoContainer>
        <img style={{width: '200px'}} src={Logo} />{/* <Logo type={isTabletMax ? 'colored' : 'white'} /> */}
      </LogoContainer>
      
      <Menu>{siderBarMenu}</Menu>

      <BottomContent>
        <div>
          <img src={Logo} style={{height: '30px'}} />
        </div>
        <p>Модуль ПатоКоннект,</p>
        <p>все права защищены</p>
      </BottomContent>
    </SideBarContainer>
  );
};
