import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const StyledBox = styled(Box)`
  position: relative;
  width: 431px;
  background-color: #f5f7fa;
  border-radius: 10px;
  border: 1px solid #c7cfd6;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 80vh;
`;
export const StyledTitle = styled.h3`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: #0a0a0a;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 20px;
  padding-right: 10px;
`;
export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 70vh;
`;
export const Cross = styled.div`
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
`;
