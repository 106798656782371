import styled from "styled-components";
import { Button } from "../button";
interface LinkProps {
    active: boolean;
}

export const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 3px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
`;

export const BackButton = styled(Button)`
  min-width: 80px !important;
`;

export const Separator = styled.span`
  margin: 0 8px;
  color: #9B9A9A;
`;
export const Link = styled.span<LinkProps>`
  color: ${(props) => (props.active ? '#0A0A0A' : '#9B9A9A')};

  &:hover {
    cursor: ${(props) => (props.active ? 'none' : 'pointer')};
  }
`;
