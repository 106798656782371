import {CenteredContainer, ContentWrapper, LoginLogoWraper, PageWrapper} from "./styled";
import {WrapperTitle} from "../../components/title/Title";
import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "../../components/button";
import { postLogin } from "../../axios/auth/authApi";
import { useNavigate } from "react-router-dom";
import {getCurrentUser} from "../../axios/staff/staffApi";
import { Input } from "../../components/customInput";
import { ErrorWrapper } from "../../components/modals/saveEditUser/styled";
// @ts-ignore
import Logo from "../../icons/logo.png";
import { LogoContainer } from "../../components/sideBar/styled";

export function Login() {
    const { t, i18n } = useTranslation();
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    
    const onHandleSubmit = (e: any) => {
        e.preventDefault();
        setError(false);

        postLogin({
            username: email,
            password: password
        }).then(async ({data}) => {
            localStorage.setItem('access', data.access);
            localStorage.setItem('refresh', data.refresh);
            localStorage.setItem('is_admin', data.is_admin);
            const currentUser: any = await getCurrentUser();
            localStorage.setItem('currentUser', JSON.stringify(currentUser.data));
            
            
            const profile = JSON.parse(localStorage.getItem('currentUser') ?? '');

            if (!data.is_admin) {
                navigate('/folders');
            } else {
                navigate('/users');
            }

        }).catch((error) => {
            setError(true);
        })
    }

    const errorContainer = useMemo(() => {
        if (error) {
            return (<ErrorWrapper>
                <div>Неверный логин или пароль</div>
            </ErrorWrapper>)
        }
    }, [error]);

    return(
        <CenteredContainer>
            <LoginLogoWraper>
                <img src={Logo} />
            </LoginLogoWraper>
            <PageWrapper>
                <WrapperTitle>{t('login.enter')}</WrapperTitle>
                <ContentWrapper onSubmit={onHandleSubmit}>
                    <Input name='login' value={email} onChange={(e: any)=>{setEmail(e.target.value)}} label={t('login.email')} placeholder={t('login.placeholder.email')}></Input>
                    <Input name='password' type="password" value={password} onChange={(e: any)=>{setPassword(e.target.value)}} label={t('login.password')} placeholder={t('login.placeholder.password')}></Input>            
                    {errorContainer}
                    <Button  variant="contained"
                             body={t('ui.button.enter')}
                             type={'submit'}
                             style={{marginTop: '8px', height: '44px'}}></Button>
                    <div>
                        <img src={Logo} style={{height: '30px'}} />
                    </div>
                </ContentWrapper>
            </PageWrapper>
        </CenteredContainer>

    )
}
