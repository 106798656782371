import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {ContentContainer, PageWrapper, TitleWrapper} from "./style";
import {WrapperTitle} from "../../components/title/Title";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import {HeadCell, StyledPagination, StyledTableRow, Table, TableCell} from "../../components/Table";
import ConfirmModal from "../../components/modals/confirmModal/ConfirmModal";
import SaveEditFolderModal from "../../components/modals/saveEditFolderModal/SaveEditFolderModal";
import CheckboxCustom from "../../components/checkbox/Checkbox";
import SaveEditRoleModal from "../../components/modals/saveEditRoleModal/SaveEditRoleModal";
import { Button } from "../../components/button";
import { IconRole } from "../../icons/IconRole";
import { IconEdit } from "../../icons/IconEdit";
import { IconCross } from "../../icons/IconCross";
import { IconButton } from "../../components/IconButton"
import SaveEditUserModal from "../../components/modals/saveEditUser/SaveEditUserModal";
import {deleteUser, getUsers} from "../../axios/staff/staffApi";
import { headerButtonActions } from "../../store/headerButton";
import { useAppActions } from "../../store/utils";
import { IconPlus } from "../../icons/IconPlus";
import { UserArg } from "../../axios/role/type";
import { usePagination } from "../../hooks/usePagination";
import { Pagination } from "../../components/Pagination";

export function Users() {
	const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [userList, setUserList] = useState([]);
    const [selectUser, setSelectUser] = useState<number>();
    const [totalCount, setTotalCount] = useState();
    // const [pagination, setPagination] = useState({});
    const { pagination, onPageChange, resetPagination } = usePagination({
        offset: 0,
        limit: 7,
        pageNumber: 0,
      });

    const { setHeaderButtonComponent } = useAppActions(headerButtonActions);

    useEffect(() => {
        const offset = (pagination.pageNumber + 1) != 1 
            ? pagination.limit * (pagination?.pageNumber ?? 1)
            : 0;
        getUsers(offset, pagination.limit).then((data) => {
            setUserList(data.data.results);
            setTotalCount(data.data.count);

        });
    }, [pagination.pageNumber])

    useEffect(() => {
        if (Math.ceil((totalCount ?? 0)/pagination.limit) < (pagination.pageNumber + 1) && ((pagination.pageNumber + 1) > 1)) {
            onPageChange(pagination.pageNumber - 1)
        }
    }, [totalCount, pagination.pageNumber]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEditOpen = (user: number) => {
        setSelectUser(user);
        setEditOpen(true);
    };

    const handleEditClose = () => {
        setEditOpen(false);
        setSelectUser(undefined);
    };
    const handleDeleteOpen = (user: number) => {
        setSelectUser(user);
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        setSelectUser(undefined);
    };

    const handleSaveUser = () => {
        const offset = (pagination.pageNumber + 1) != 1 
            ? pagination.limit * (pagination?.pageNumber ?? 1)
            : 0;
        getUsers(offset, pagination.limit).then((data) => {
            setUserList(data.data.results);
            setTotalCount(data.data.count);

            // setPagination({
            //     count: data.data.count,
            //     next: data.data.next,
            //     previous: data.data.previous,
            // })
        });
        setOpen(false);
        setEditOpen(false);
        setDeleteOpen(false);
    };

    const handleDeleteUser = () => {
        deleteUser(selectUser ?? 0).then(() => {
            handleSaveUser();
        })
    }

    useEffect(() => {
        setHeaderButtonComponent(<Button onClick={handleOpen} startIcon={<IconPlus />} variant={"contained"} body={t('users.createUser')} />)
    }, []);

    const editModal = useMemo(() => {
        if (selectUser) {
            return (
                <SaveEditUserModal open={editOpen} onClose={handleEditClose} onSave={handleSaveUser} isEdit={true} userId={selectUser} />
            )
        }
    }, [editOpen,selectUser]);
    
    const deleteModal = useMemo(() => {
        if (selectUser) {
            return (
                <ConfirmModal open={deleteOpen} onClose={handleDeleteClose} onSubmit={handleDeleteUser} submitButtonText={t('ui.button.delete')} title={t('ui.modal.title.confirmDeleteUser')}/>
            )
        }
    }, [deleteOpen,selectUser]);

    const headCells: HeadCell[] = [
        {
            disablePadding: true,
            label: t('ui.table.name'),
            // width: 10px,
            numeric: false,
            id: '1',
        },
        {
            disablePadding: true,
            width: '300px',
            label: t('ui.table.dateOfCreate'),
            numeric: false,
            id: '2',
        },
        {
            disablePadding: true,
            width: '300px',
            label: t('ui.table.dateOfChange'),
            numeric: false,
            id: '3',
        },
        {
            disablePadding: true,
            width: '300px',
            label: t('ui.table.role'),
            numeric: false,
            id: '4',
        },
    ];
    const [checked, setChecked] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const tableRows = useMemo(() => {
        return userList?.map((row: any) => (
            <StyledTableRow key={row.id}>
                <TableCell align="left">{`${row.last_name} ${row.first_name}`}</TableCell>
                <TableCell align="left">{row.date_create}</TableCell>
                <TableCell align="left">{row.date_update}</TableCell>
                <TableCell align="left">{row.role}</TableCell>
                <TableCell align="right" width={'150px'}>
                    {
                        <div>
                            <IconButton icon={<IconEdit />} onClick={() => handleEditOpen(row.id)}/>
                            <IconButton icon={<IconCross />} onClick={() => handleDeleteOpen(row.id)}/>
                        </div>
                    }
                </TableCell>
            </StyledTableRow>
        ));
    }, [userList]);

    return(
        <>
            <PageWrapper>
                {/* <TitleWrapper>
                        <WrapperTitle>Пользователи</WrapperTitle>
                        <Breadcrumb>Пользователи / sss</Breadcrumb>
                </TitleWrapper> */}

                <ContentContainer>
                    <Table headCells={headCells} rows={tableRows}/>
                    <Pagination
                        pageNumber={pagination.pageNumber ?? 0}
                        pageCount={Math.ceil((totalCount ?? 0)/pagination.limit)}
                        onChange={onPageChange}
                    />
                </ContentContainer>
                {/* <CheckboxCustom
                    checked={checked}
                    onChange={handleChange}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    label='hello'
                /> */}
                <SaveEditUserModal open={open} onClose={handleClose} onSave={handleSaveUser} isEdit={false}/>
                {editModal}
                {deleteModal}
            </PageWrapper>  
        </>
    )
}
