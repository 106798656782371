import { FC } from 'react';

export const IconFile: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M10.9608 2.11907H5.07845C4.70403 2.11907 3.38242 1.85116 3.11767 2.11907C2.85291 2.38698 3.11767 2.72432 3.11767 3.10319V14.9127C3.11767 15.2916 2.85291 16.613 3.11767 16.8809C3.38242 17.1488 4.70403 16.8809 5.07845 16.8809H13.9019C14.2764 16.8809 14.6176 17.1488 14.8823 16.8809C15.1471 16.613 14.8823 15.2916 14.8823 14.9127V6.05557L10.9608 2.11907Z" stroke="#0A0A0A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11 2V7H16" stroke="#0A0A0A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    );
};
