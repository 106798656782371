import { IconFolder } from "../../icons/IconFolder";
import { IconRole } from "../../icons/IconRole";
import { IconUser } from "../../icons/IconUser";

export const menu = [
  {
    name: 'users',
    icon: IconUser,
    path: '/users',//RouterPaths.Users,
    permission: 'USERS',
    secondPath: 'IS_ADMIN',
    isAdmin: true
  },
  {
    name: 'roles',
    icon: IconRole,
    path: '/roles',//RouterPaths.Roles,
    permission: 'ROLES',
    isAdmin: true
  },
  {
    name: 'folders',
    icon: IconFolder,
    secondPath: 'IS_NO_ADMIN',
    path: '/folders',//RouterPaths.Roles,
    permission: 'FOLDER',
  },
  // {
  //   name: 'folders',
  //   icon: IconFolders,
  //   path: RouterPaths.Folders,
  //   permission: 'FOLDERS',
  // },
];
