import {axiosInstance} from "../index";
import { Folder } from "./type";

export function getFolders(offset: number, limit: number) {
    return axiosInstance.get(`/folder/?offset=${offset}&limit=${limit}`)
}

export function getFoldersInFolders(id: string, offset: number, limit: number) {
    return axiosInstance.get(`/folder/${id}/?offset=${offset}&limit=${limit}`)
}

export function postCreateFolderInRoot(data: Folder) {
    return axiosInstance.post(`/folder/`, data)
}

export function deleteFolder(id: number) {
    return axiosInstance.delete(`/folder/${id}/`)
}

export function postCreateFolderInFolderInRoot(folderId: string, data: Folder) {
    return axiosInstance.post(`/folder/${folderId}/node/`, data)
}

export function updateFoldert(folderId: number, data: Folder) {
    return axiosInstance.patch(`/folder/${folderId}/`, data)
}
