import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { checkFileName } from '../axios/case/caseApi';

interface FileUploadState {
  file: File | null;
  flag: boolean;
  alertText: string;
  alert: boolean;
  progress: number;
  fileName: string;
  caseId: number | null;
  files: { file: string; done: boolean }[];
}

const initialState: FileUploadState = {
  file: null,
  fileName: '',
  flag: false,
  alertText: '',
  alert: false,
  progress: 0,
  caseId: null,
  files: [],
};

export const uploadFile = createAsyncThunk(
  'fileUpload/uploadFile',
  async (
    {
      file,
      id,
      controller,
      cancelCondition,
    }: {
      file: File;
      id: number;
      controller: AbortController;
      cancelCondition: boolean;
    },
    { dispatch }
  ) => {
    const chunkSize = 1024 * 1024 * 2;
    const fileSize = file.size;
    let offset = 0;
    const chunks: [number, number, Blob][] = [];

    if (file.name && id) {
      await checkFileName(id, file.name)
        .then(async (res) => {
          try {
            dispatch(fileUploadSlice.actions.setCaseId(id));
            dispatch(fileUploadSlice.actions.setFlag(false));
            dispatch(fileUploadSlice.actions.setFileName(file.name));

            while (offset + chunkSize < fileSize) {
              chunks.push([
                offset,
                offset + chunkSize,
                file.slice(offset, offset + chunkSize),
              ]);
              offset += chunkSize;
            }
            chunks.push([offset, fileSize, file.slice(offset)]);

            const progressPart = 100.0 / chunks.length;

            const token = localStorage.getItem('access');
            const promises: Promise<void>[] = [];

            for (let i = 0; i < chunks.length; i++) {
              const x = chunks[i];
              const form = new FormData();
              form.append('part', x[2]);
              form.append('part_number', i.toString());
              form.append('filename', file.name);
              form.append('filetype', file.type);
              if (cancelCondition) {
                controller.abort(); // Отменяем отправку
                return; // Выходим из функции
              }

              const promise = axios
                .post(
                  `${process.env.REACT_APP_BASE_URL}/case/${id}/upload/`,
                  form,
                  {
                    headers: {
                      Connection: 'keep-alive',
                      'Keep-Alive': 'timeout=10, max=1000',
                      Authorization: `Bearer ${token}`,
                      'Content-Range': `bytes ${x[0]}-${x[1]}/${fileSize}`,
                      'X-File-Chunk': 'chunk',
                    },
                    signal: controller.signal,
                  }
                )
                .then(() => {
                  dispatch(fileUploadSlice.actions.setProgress(progressPart));
                });

              promises.push(promise);
            }
            await Promise.all(promises);

            const form = new FormData();
            form.append('filename', file.name);
            form.append('filetype', file.type);
            await axios.post(
              `${process.env.REACT_APP_BASE_URL}/case/${id}/upload/`,
              form,
              {
                headers: {
                  Connection: 'keep-alive',
                  'Keep-Alive': 'timeout=10, max=1000',
                  Authorization: `Bearer ${token}`,
                  'X-File-Chunk': 'Complete',
                },
                signal: controller.signal,
              }
            );
          } catch (err) {}
        })
        .finally(() => {
          dispatch(fileUploadSlice.actions.setFlag(true));
        })
        .catch(() => {
          dispatch(
            setAlert({
              alertText: 'Файл с таким названием уже существует',
              alert: true,
            })
          );
        });
    } else {
    }
    // return { success: true };
  }
  // catch (error) {
  //     dispatch(setAlert({ alertText: 'File upload failed', alert: true }));
  //     throw error; // Прокидываем ошибку, чтобы обработать её внутри createAsyncThunk
  // }
  // }
);

export const fileUploadSlice = createSlice({
  name: 'fileUpload',
  initialState,
  reducers: {
    setAlert: (
      state,
      action: PayloadAction<{ alertText: string; alert: boolean }>
    ) => {
      state.alertText = action.payload.alertText;
      state.alert = action.payload.alert;
    },
    setProgress: (state, action: PayloadAction<number>) => {
      if (action.payload) {
        state.progress += action.payload;
      } else state.progress = 0;
    },
    setCaseId: (state, action: PayloadAction<number | null>) => {
      state.caseId = action.payload;
    },
    setFlag: (state, action: PayloadAction<boolean>) => {
      state.flag = action.payload;
    },
    setFileName: (state, action: PayloadAction<string>) => {
      state.fileName = action.payload;
    },
    setFiles: (
      state,
      action: PayloadAction<{ file: string; done: boolean } | null>
    ) => {
      if (action.payload == null) {
        state.files = [];
      } else {
        const index = state.files.findIndex(
          (item) => item.file === action.payload!.file
        );
        if (index !== -1) {
          state.files.splice(index, 1);
          state.files.push(action.payload);
        } else {
          state.files.push(action.payload);
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.pending, (state) => {
        // You can handle the pending state if needed.
      })
      .addCase(uploadFile.fulfilled, (state) => {
        // File upload has been completed, you can perform any post-upload actions here.
      })
      .addCase(uploadFile.rejected, (state, action) => {
        // state.alertText = 'File upload failed';
        // Set an error message
        state.alert = true;
      });
  },
});

export const {
  setAlert,
  setProgress,
  setFlag,
  setFiles,
  setCaseId,
  setFileName,
} = fileUploadSlice.actions;
export const fileUploadReducer = fileUploadSlice.reducer;
