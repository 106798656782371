import React, { FC, ReactNode } from 'react';

import {Button} from "../../button";
import {t} from "i18next";
import {ButtonContainer, Cross, StyledBox, StyledModal, StyledTitle} from "./styled";
import {ErrorWrapper} from "../saveEditUser/styled";

interface ModalProps {
    open: boolean;
    onClose: () => void;
    submitButtonText: string;
    onSubmit: () => void;
    title: string;
    error?: any;
}


const ConfirmModal: FC<ModalProps> = ({ error, open, onClose, title, submitButtonText, onSubmit }) => {
    console.log(error)
    return (
        <StyledModal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <StyledBox>
                <Cross onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#475F7B"/>
                    </svg>
                </Cross>
                <StyledTitle>{title}</StyledTitle>
                {error && (<ErrorWrapper>
                    {error.title}
                    {error.body}
                </ErrorWrapper>)}
                <ButtonContainer>
                    <Button body={t('ui.button.cancel')} onClick={onClose}></Button>
                    <Button variant="contained" body={submitButtonText} onClick={onSubmit}></Button>
                </ButtonContainer>
            </StyledBox>
        </StyledModal>
    );
};

export default ConfirmModal;
