import React, {FC, ReactNode, useEffect, useState} from 'react';

import {Button} from "../../button";
import {t} from "i18next";
import { Cross, StyledBox, StyledModal, StyledTitle } from "./styled";
import {Input} from "../../customInput";
import {Select, SelectItem} from "../../Select";
import {PermissionItem, RoleArg, UserArg} from "../../../axios/role/type";
import {addRole, getAllRoles, getRoleById, getRoles, updateRole} from "../../../axios/role/roleApi";
import {MenuItem} from "@mui/material";
import {StyledForm} from "../saveEditUser/styled";
import CheckboxCustom from "../../checkbox/Checkbox";
interface CheckboxState {
    createFolders: boolean;
    createCases: boolean;
    editFolders: boolean;
    editCases: boolean;
    downloadFolders: boolean;
    isAdmin: boolean;
}
interface ModalProps {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    isEdit: boolean;
    roleId?: number;
}

const SaveEditRoleModal: FC<ModalProps> = ({ open, onClose, onSave, isEdit, roleId }) => {
    //TODO fix any
    const [roleData, setRoleData] = useState<RoleArg>({} as RoleArg);
    const [roles, setRoles] = useState<PermissionItem[]>([]);
    const [errorFields, setErrorFields] = useState<{ field: string; message: any; }[]>([]);

    const [checkboxState, setCheckboxState] = useState<CheckboxState>({
        createFolders: false,
        createCases: false,
        editFolders: false,
        editCases: false,
        downloadFolders: false,
        isAdmin: false,
    });

    const listOfCheckbox = [
        {
            id: 1,
            name: 'Создание папок',
            state: 'createFolders',
            label: 'ui.checkbox.createFolders',
        },
        {
            id: 2,
            name: 'Создание случаев',
            state: 'createCases',
            label: 'ui.checkbox.createCases',
        },
        {
            id: 3,
            name: 'Редактирование папок',
            state: 'editFolders',
            label: 'ui.checkbox.editFolders',
        },
        {
            id: 4,
            name: 'Редактирование случаев',
            state: 'editCases',
            label: 'ui.checkbox.editCases',
        },
        {
            id: 5,
            name: 'Загрузка файлов',
            state: 'downloadFolders',
            label: 'ui.checkbox.downloadFolders',
        },
    ];

    useEffect(() => {
        setRoleData({} as RoleArg);
        setErrorFields([])
        setCheckboxState({
            createFolders: false,
            createCases: false,
            editFolders: false,
            editCases: false,
            downloadFolders: false,
            isAdmin: false,
        })
        getAllRoles().then((data) => {
            const filteredData = data.data.results.map((item: PermissionItem) => ({
                id: item.id,
                name: item.name,
            }));
            setRoles([{id: 0, name: '-'}, ...filteredData]);
        });
        if (isEdit) {
            getRoleById(roleId ?? 0).then(({data}) => {
                setRoleData({...data, child_roles: data.child_roles[0]?.id});
                setCheckboxState({
                    createFolders: !!data.permissions.some((per: any) => per.id === listOfCheckbox.find((it) => it.state === 'createFolders')?.id),
                    createCases: !!data.permissions.some((per: any) => per.id === listOfCheckbox.find((it) => it.state === 'createCases')?.id),
                    editFolders: !!data.permissions.some((per: any) => per.id === listOfCheckbox.find((it) => it.state === 'editFolders')?.id),
                    editCases: !!data.permissions.some((per: any) => per.id === listOfCheckbox.find((it) => it.state === 'editCases')?.id),
                    downloadFolders: !!data.permissions.some((per: any) => per.id === listOfCheckbox.find((it) => it.state === 'downloadFolders')?.id),
                    isAdmin: !!data.is_admin,
                })                
            });
        }
    }, [open, roleId]);

    const handleEditFields = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        setRoleData((prevState) => {
            return {
                ...prevState,
                [name]: value,
            }
        })
    }
    const permissions = listOfCheckbox
        //@ts-ignore
        .filter((checkbox) => checkboxState[checkbox.state])
        .map((checkbox) => checkbox.id);


    const errorHandler = (error: any) => {
        const errorFields = Object.entries(error.response.data).map(([field, messages]) => ({
            field,
            // @ts-ignore
            message: messages[0],
        }));
        setErrorFields(errorFields);
    }
    const handleCheckboxChange = (name: string) => {        
        setCheckboxState((prevState) => ({
            ...prevState,
            // @ts-ignore
            [name]: !prevState[name],
        }));
    };
    const handleCheckboxAdminChange = (name: string) => {
        setCheckboxState((prevState) => ({
            ...prevState,
            // @ts-ignore
            [name]: !prevState[name],
        }));
        setRoleData((prevState) => {return {...prevState, is_admin: !prevState.is_admin}})
    };
    const onHandleSubmit = (e: any) => {
        e.preventDefault();
        if (!isEdit) {
            addRole({
                name: roleData.name,
                permissions: permissions,
                is_admin: roleData.is_admin,
                child_roles: !roleData.child_roles ? [] : [roleData.child_roles ?? 0]
            }).then(() => {
                onSave();
            })
                .catch((error: any) => {
                    errorHandler(error);
                });
        } else {
            updateRole({
                id: roleData.id,
                name: roleData.name,
                permissions: permissions,
                is_admin: roleData.is_admin,
                child_roles: roleData?.child_roles ? [roleData?.child_roles] : undefined
            }).then(() => {
                onSave();
            })
                .catch((error) => {
                    errorHandler(error);
                });
        }
    }

    return (
        //TODO
        <StyledModal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <StyledBox>
                <Cross onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#475F7B"/>
                    </svg>
                </Cross>
                <StyledForm onSubmit={onHandleSubmit}>
                    <StyledTitle>{isEdit ? t('ui.modal.title.editRole') : t('ui.modal.title.createRole')}</StyledTitle>            
                    <Input name='name' value=
                        {roleData?.name} onChange={handleEditFields} label={t('ui.input.name')} placeholder={t('ui.input.name')} errors={errorFields}></Input>
                    <Select
                        value={roleData?.child_roles || (roles[0] ? roles[0].id : '')}
                        color="secondary"
                        name={'child_roles'}
                        label={t('ui.input.parentRole')}
                        onChange={handleEditFields}
                        errors={errorFields}
                        options={roles?.map((role) => { return {id: role.id, name: role.name}})}>
                        {roles?.map((role) => (
                            <MenuItem key={role.id} value={role.id}>
                                {role.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <div>
                        {listOfCheckbox.map((checkbox) => (
                            <CheckboxCustom
                                key={checkbox.id}
                                //@ts-ignore
                                checked={checkboxState[checkbox.state]}
                                onChange={() => handleCheckboxChange(checkbox.state)}
                                label={t(checkbox.label)}
                            />
                        ))}
                        <CheckboxCustom
                            //@ts-ignore
                            checked={checkboxState.isAdmin}
                            onChange={() => handleCheckboxAdminChange("isAdmin")}
                            label={t('ui.checkbox.isAdmin')}
                        />
                    </div>
                    <Button type={'submit'} variant="contained" body={isEdit ? t('ui.button.update') : t('ui.button.create')}></Button>

                </StyledForm>
            </StyledBox>
        </StyledModal>
    );
};

export default SaveEditRoleModal;
