import { FC } from 'react';

export const IconUnlock: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z" stroke="#006666" />
      <path d="M19 10H5C3.89543 10 3 10.8954 3 12V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V12C21 10.8954 20.1046 10 19 10Z" stroke="#006666" />
      <path d="M7 10V7C7.00005 5.89936 7.36326 4.8295 8.03329 3.95633C8.70333 3.08315 9.64276 2.45546 10.7059 2.17059C11.769 1.88572 12.8964 1.9596 13.9133 2.38077C14.9302 2.80193 15.7796 3.54685 16.33 4.5" stroke="#006666" />
    </svg>
  );
};
