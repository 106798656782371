import styled from "styled-components";

export const Title = styled.h1`
  margin: 0;
  color: #475F7B;
  font-feature-settings: 'clig' off, 'liga' off;
  /* H1 */
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`;
