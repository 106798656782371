import axios from 'axios';
import { refreshToken } from './auth/authApi';

const server_url = process.env.REACT_APP_BASE_URL;

function parseJwt(token: string) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

axios.defaults.withCredentials = true;
export const axiosInstance = axios.create({
  baseURL: server_url,
});

export const axiosRefreshInstance = axios.create({
  baseURL: server_url,
});

// axiosRefreshInstance.interceptors.request.use(async (config: any) => {
//   const refresh = localStorage.getItem('refresh');
//   if (refresh) {
//     config.headers.Authorization = `Bearer ${refresh}`
//     return config;
//   }
// });

axiosInstance.interceptors.request.use(async (config: any) => {
  const access = localStorage.getItem('access');
  const refresh = localStorage.getItem('refresh');
  if (access && refresh) {
    const expToken = parseJwt(access || '');
    if (expToken && new Date().valueOf() >= expToken.exp * 1000) {
      try {
        const newToken: any = await refreshToken(refresh);
        const newAccess = newToken.data.access;
        const newRefresh = newToken.data.refresh;
        const newIsAdmin = newToken.data.is_admin;

        if (!newAccess || !newRefresh) {
          localStorage.clear();
          return window.location.replace('/login');
        }

        localStorage.setItem('access', newAccess);
        localStorage.setItem('refresh', newRefresh);
        localStorage.setItem('is_admin', newIsAdmin);
        config.headers.Authorization = `Bearer ${newAccess}`;
        config.params = config.params || {};
        return config;
      } catch {
        localStorage.clear();
        return window.location.replace('/login');
      }
    }
  }
  config.headers.Authorization = `Bearer ${access}`;
  config.params = config.params || {};
  //console.log(config)
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const errorCode = error.response?.status;

    console.log(errorCode);
    const originalRequest = error.config;
    if (
      errorCode == 401 &&
      window.location.pathname !== '/login' &&
      !originalRequest._retry
    ) {
      console.error('ERROR 401');
      localStorage.clear();
      window.location.href = '/login';
    }

    return Promise.reject(error);
  }
);
