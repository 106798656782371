import { useCallback, useState } from 'react';
import { boolean } from 'yargs';

interface Pagination {
  offset: number;
  limit: number;
  pageNumber: number;
  page?: number;
}

export const usePagination = (initialValue: Pagination) => {
  const [pagination, setPagination] = useState(initialValue);

  const onPageChange = useCallback((value: number) => {
    setPagination((prevState: Pagination) => {
      return {
        ...prevState,
        pageNumber: value,
      };
    });
  }, []);

  const onRowsPerPageChange = useCallback((rows: number) => {
    // setPagination({
    //   pageNumber: 0,
    //   pageSize: rows,
    // });
  }, []);

  const resetPagination = useCallback(() => {
    setPagination((prevState: Pagination) => {
      return {
        ...prevState,
        pageNumber: 0,
      };
    });
  }, []);

  return { pagination, 
    onPageChange, onRowsPerPageChange, resetPagination };
};
