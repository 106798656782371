import React, {FC, ReactNode, useEffect, useState} from 'react';

import {Button} from "../../button";
import {t} from "i18next";
import {Cross, ErrorWrapper, StyledBox, StyledForm, StyledModal, StyledTitle} from "./styled";
import {Input} from "../../customInput";
import {getAllRoles, getRoles} from "../../../axios/role/roleApi";
import {PermissionItem, RolesResponse, UserArg} from "../../../axios/role/type";
import {MenuItem} from "@mui/material";
import {addUser, getUserbyId, getUsers, updateUser} from "../../../axios/staff/staffApi";
import { Select } from '../../Select';

interface ModalProps {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    isEdit: boolean;
    userId?: number;
}

const SaveEditUserModal: FC<ModalProps> = ({ open, onClose, onSave, isEdit, userId }) => {
    const [userData, setUserData] = useState<UserArg>({} as UserArg);
    const [roles, setRoles] = useState<PermissionItem[]>([]);
    const [errorFields, setErrorFields] = useState<{ field: string; message: any; }[]>([]);
    useEffect(() => {
        setUserData({} as UserArg);
        setErrorFields([]);
        getAllRoles().then((data) => {
            const filteredData = data.data.results.map((item: PermissionItem) => ({
                id: item.id,
                name: item.name,
            }));
            setRoles([{name: '-', id: 0}, ...filteredData]);
        });
    }, [open]);
    useEffect(() => {
        if (isEdit && roles) {
            getUserbyId(userId ?? 0).then(({data}) => {
                setUserData({...data, role_id: data.role.id});
            });
        }
    }, [userId, roles]);
    const handleEditFields = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        setUserData((prevState) => {
            return {
                ...prevState,
                [name]: value,
            }
        })
    }

    const errorHandler = (error: any) => {
        const errorFields = Object.entries(error.response.data).map(([field, messages]) => ({
            field,
            // @ts-ignore
            message: messages[0],
        }));
        setErrorFields(errorFields);
    }

    const onHandleSubmit = (e: any) => {
        e.preventDefault();
        if (!isEdit) {
            addUser({
                first_name: userData.first_name,
                last_name: userData.last_name,
                username: userData.username,
                password: userData.password,
                email: userData.email,
                role_id: userData.role_id
            }).then(() => {
                onSave();
            })
            .catch((error) => {
                errorHandler(error);

            });
        } else {
            updateUser({
                id: userData.id,
                first_name: userData.first_name,
                last_name: userData.last_name,
                username: userData.username,
                password: userData.password,
                email: userData.email,
                role_id: userData.role_id
            }).then(() => {
                onSave();
            })
            .catch((error) => {
                errorHandler(error);
            });
        }
    }

    return (
        <StyledModal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <StyledBox>
                <Cross onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#475F7B"/>
                    </svg>
                </Cross>
                <StyledForm onSubmit={onHandleSubmit}>
                    <StyledTitle>{isEdit ? t('ui.modal.title.updateUser') : t('ui.modal.title.createUser')}</StyledTitle>
                    <Input name='username' value=
                    {userData?.username} onChange={handleEditFields} label={t('ui.input.userName')} placeholder={t('ui.input.userName')} errors={errorFields}></Input>
                    <Input name='email' value={userData?.email} onChange={handleEditFields} label={t('ui.input.chooseEmail')} placeholder={t('ui.input.email')} errors={errorFields}></Input>

                    <Input name='first_name' value={userData?.first_name} onChange={handleEditFields} label={t('ui.input.firstName')} placeholder={t('ui.input.firstName')} errors={errorFields}></Input>
                    <Input name='last_name' value={userData?.last_name} onChange={handleEditFields} label={t('ui.input.lastName')} placeholder={t('ui.input.firstName')} errors={errorFields}></Input>

                    <Select
                        value={userData?.role_id || (roles[0] ? roles[0].id : '')}
                        color="secondary"
                        name={'role_id'}
                        label={t('ui.input.role')}
                        onChange={handleEditFields}
                        errors={errorFields}
                        options={roles?.map((role) => { return {id: role.id, name: role.name}})}>
                            {roles?.map((role) => (
                                <MenuItem key={role.id} value={role.id}>
                                    {role.name}
                                </MenuItem>
                            ))}
                    </Select>
                    <Input name='password' type={'password'} value={userData?.password} onChange={handleEditFields} label={t('ui.input.password')} placeholder={t('ui.input.password')} errors={errorFields}></Input>
                    <Button type={'submit'} variant="contained" body={isEdit ? t('ui.button.update') : t('ui.button.create')}></Button>

                </StyledForm>
            </StyledBox>
        </StyledModal>
    );
};

export default SaveEditUserModal;
