import {axiosInstance} from "../index";
import {RoleArg} from "./type";

export function getRoles(offset: number, limit: number) {
    return axiosInstance.get(`/role/?offset=${offset}&limit=${limit}`)
}
export function getAllRoles() {
    return axiosInstance.get(`/role/?offset=0&limit=100`)
}
export function getRoleById(id: number) {
    return axiosInstance.get(`/role/${id}/`)
}
export function addRole(data: RoleArg) {
    return axiosInstance.post(`/role/`, data)
}
export function updateRole(data: RoleArg) {
    return axiosInstance.patch(`/role/${data.id}/`, data)
}

export function deleteRole(id: number) {
    return axiosInstance.delete(`/role/${id}/`)
}
