// @ts-nocheck
// TODO: remove ts-nocheck
import { ElementType, FC, ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useController, UseControllerProps } from 'react-hook-form';
import { Box, MenuItemProps, SelectProps as MuiSelectProps } from '@mui/material';

import * as S from './styled';
import { IconDropperDown } from '../../icons/IconDropperDown';
import { ErrorWrapper } from '../modals/saveEditUser/styled';

// TODO: add multiply values, add chips
// TODO: rework function getNameByValue for multiply values as string[]

type SelectProps<FormValues, Option> = MuiSelectProps & {
  control?: UseControllerProps<FormValues>;
  options: Option[] | undefined;
  name: string;
  errors?: any;
};

export const Select = <FormValues, Option>(
  props: SelectProps<FormValues, Option>
): ReactElement => {
  const { options, label, placeholder, name, children, multiple, errors, ...rest } = props;

  const { t } = useTranslation();
  // const hasError = errors ? !!errors[control.name] : false;

  const getNameByValue = (value: string): string | null => {
    let name: string;
    if (Array.isArray(options)) {
      options.find((option: any) => {
        if (Object.values(option).includes(value)) {
          name = option['name'];
          return;
        }
      });
      // @ts-ignore
      return name;
    }
    return null;
  };

  const Wrapper = label ? S.LabelWrapper : (S.SelectWrapper as ElementType);

  return (
    <Wrapper>
      {label && <S.Label>{label}</S.Label>}

      <S.Select
        displayEmpty
        IconComponent={IconDropperDown}
        name={name}
        renderValue={(selected: any) => {
          if (multiple) {
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected?.map((value: any) => (
                  <S.Chip key={value} label={getNameByValue(value)}></S.Chip>
                ))}
              </Box>
            );
          } else {
            if ((selected as string | string[])?.length === 0) {
              return <S.Placeholder>{placeholder}</S.Placeholder>;
            }
            return (getNameByValue(selected as string) || selected) as ReactNode;
          }
        }}
        {...rest}
        // error={hasError}
        multiple={multiple}
      >
        <S.DisabledItem disabled value={''}>
          {placeholder}
        </S.DisabledItem>
        {children ? children : <S.NoItems disabled>{t('ui.select.noItems')}</S.NoItems>}
      </S.Select>

      <ErrorWrapper>
        {errors?.map((error: any) => (
            error.field === name && <div key={error.field}>{error.message}</div>
        ))}
      </ErrorWrapper>
      {/* {hasError && <ErrorMessage fieldName={control.name} errors={errors} />} */}
    </Wrapper>
  );
};

export const SelectItem: FC<MenuItemProps> = ({ children, ...props }) => {
  return <S.Item {...props}>{children}</S.Item>;
};
