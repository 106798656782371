import styled from 'styled-components';

export const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #c7cfd6;
  //padding: 5px;
  border-radius: 4px;
  overflow-y: auto;
  height: 120px;
`;
export const FileItem = styled.div`
  color: #9b9a9a;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //border: 1px solid #ddd;
  border-radius: 4px;
  &:hover {
    background-color: #eef6f6;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  //margin: -20px 0;
`;
export const Label = styled.span`
  display: inline-block;
  margin-bottom: 4px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #0a0a0a;
  margin-left: 3px;
`;
export const UploadBtnIcon = styled.div`
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 50%;
  }
  padding: 8px;
  width: 24px;
  height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const UploadBanner = styled.div`
  position: fixed;
  right: 32px;
  bottom: 0;
  width: 443px;
  z-index: 10;
  & .header {
    background: #006666;
    color: #fff;
    border-top: 1px solid #006666;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .controls {
      display: flex;
      align-items: center;
      gap: 20px;
      & div {
        cursor: pointer;
      }
    }
  }
  & .files {
    max-height: 240px;
    overflow-y: auto;
  }
  & .content {
    border: 1px solid #006666;
    padding: 15px 20px 0px;
    background: white;
  }
  & .cancelBtn {
    text-align: right;
    margin-right: 10px;
    margin-bottom: 10px;
    color: #006666;
    cursor: pointer;
  }
  & .progressBackground {
    fill: none;
    stroke-width: 8;
    stroke: #eaeaea;
  }
`;
