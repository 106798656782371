import styled from 'styled-components';
import { Chip as MuiChip, MenuItem, Select as MuiSelect } from '@mui/material';
import Multiselect from 'multiselect-react-dropdown';

export const Select = styled(MuiSelect)`
  min-height: 44px;
  background-color: #ffffff;
  width: 100%;
  border: none;
  font-family: Open Sans !important;

  // main div of Select component
  .MuiSelect-select {
    box-sizing: border-box;
    padding: 16px;

    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #5D636B;
  }

  // fieldset as OutlinedInput
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #C7CFD6;
  }

  // when hover
  &:hover {
    && fieldset {
      border-color: #006666;
    }
  }

  // when select is open
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid black;
  }

  // when select is disabled
  &.Mui-disabled {
    background-color: #fafafa;
    opacity: 0.5;
  }

  .Mui-disabled {
    cursor: not-allowed;
  }

  &:hover.Mui-disabled {
    && fieldset {
      border-color: black;
    }
  }

  &.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border: 1px solid black;
  }

  // when error occurred
  &.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: black;
  }

  &:hover.Mui-error {
    && fieldset {
      border-color: black;
    }
  }

  // expand icon
  .MuiSelect-icon {
    top: auto;
    right: 16px;
  }

  svg {
    margin-right: 16px;
  }
`;

export const Placeholder = styled.span`
  opacity: 0.5;
`;

export const Chip = styled(MuiChip)`
color: #006666 !important;
  &.css-1ie5wwd-MuiChip-root {
    height: 24px;
    background-color: transparent;
    border: 1px solid #006666;
    border-radius: 4px;
  }
  &.css-6od3lo-MuiChip-label {
    //styleName: small txt;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
  }
`;

export const Item = styled(MenuItem)`
  &.MuiMenuItem-root {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 20px;
    color: black;
  }
`;

export const DisabledItem = styled(Item)`
  &.MuiMenuItem-root {
    font-size: 1.4rem;
  }
`;

export const NoItems = styled(Item)`
  &.MuiMenuItem-root {
    justify-content: center;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelWrapper = styled.label`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
display: inline-block;
margin-bottom: 4px;
//styleName: 12px;
font-family: Open Sans;
font-size: 16px;
font-weight: bold;
line-height: 22px;
letter-spacing: 0px;
text-align: left;

color: #0A0A0A;
margin-left: 3px;
`;

export const MultiSelect = styled(Multiselect)`
  .chip {
      height: 24px;
      background-color: transparent;
      border: 1px solid #006666;
      border-radius: 4px;
      
      //styleName: small txt;
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0px;
      text-align: left;
      color: #006666;
      margin-bottom: none;

      .closeIcon {
        filter: contrast(0.6);
      }
  }
  .searchWrapper {
    min-height: 44px;
    background-color: #ffffff;
    width: 100%;
    border: 1px solid #C7CFD6;
    padding: 7px;

    &:hover {
      border-color: #006666;
    }
  }
  .option {
    //styleName: main txt;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #0A0A0A;

    &:hover {
      background: #EEF6F6;
      color: #006666;
    }
  }
  .highlightOption {
    background: #EEF6F6;
    color: #006666;
  }
  .icon_down_dir {
    &:before {
      content: "";
    }
  }
  .optionListContainer {
    &:hover: {
      backgroung: red;
    }
  }
`