import React, {FC, ReactNode, useEffect, useState} from 'react';

import {Button} from "../../button";
import {t} from "i18next";
import {Cross, SettinPermissionContainer, StyledBox, StyledModal, StyledTitle} from "./styled";
import {Input} from "../../customInput";
import {getAllRoles, getRoles} from "../../../axios/role/roleApi";
import {PermissionItem, RolesResponse, UserArg} from "../../../axios/role/type";
import {MenuItem, colors} from "@mui/material";
import {addUser, getAllUsers, getUserbyId, getUsers, updateUser} from "../../../axios/staff/staffApi";
import { Select } from '../../Select';
import { StyledForm } from '../saveEditUser/styled';
import { Radio, RadioGroup } from '../../RadioGroup';
import { getFoldersInFolders, postCreateFolderInFolderInRoot, postCreateFolderInRoot, updateFoldert } from '../../../axios/folder/folderApi';
import { Folder } from '../../../axios/folder/type';
import { RadioRowLabelContainer } from '../../RadioGroup/styled';
import { getCase } from '../../../axios/case/caseApi';
import { CaseDTO } from '../../../axios/case/type';
import { IconUnlock } from '../../../icons/IconUnock';
import Multiselect from 'multiselect-react-dropdown';
import { Label, MultiSelect } from '../../Select/styled';
import { IconDropperDown } from '../../../icons/IconDropperDown';
import { hasPermision } from '../../../utils/utils';
import { Link } from '../../link';

interface ModalProps {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    isEdit: boolean;
    folderId?: number;
    parentFolderId?: string;
}

export enum PermissionType {
    PRIVATE = 'PRIVATE',
    OPEN = 'OPEN',
    BLACK_LIST = 'BLACK_LIST',
    WHITE_LIST = 'WHITE_LIST'
}

const SaveEditFolderModal: FC<ModalProps> = ({ open, onClose, onSave, isEdit, folderId, parentFolderId }) => {
    const [folderData, setFolderData] = useState<Folder>({} as Folder);
    const [roles, setUsers] = useState([]);
    const [permissionType, setPermissionType] = useState<PermissionType>(PermissionType.OPEN);
    const [errorFields, setErrorFields] = useState<{ field: string; message: any; }[]>([]);
    useEffect(() => {
        setFolderData({} as Folder);
        setPermissionType(PermissionType.OPEN);
        setErrorFields([]);
        if (open) {
            getAllUsers().then((data) => {
                const filteredData = data.data.results.filter((it: any) => !it.is_admin).map((item: UserArg) => ({
                    id: item.id,
                    name: `${item.first_name} ${item.last_name}`,
                }));
                setUsers(filteredData);
            });
        }
    }, [open]);

    useEffect(() => {
        if (isEdit && roles && open) {
            getFoldersInFolders(`${folderId ?? 0}`, 0, 0).then(({data}) => {
                const userList = data?.white_list.length
                    ? data.white_list?.map((item: any) => { return {id: item.id, name: `${item.first_name} ${item.last_name}`}})
                    : data.black_list?.map((item: any) => { return {id: item.id, name: `${item.first_name} ${item.last_name}`}})

                setFolderData({
                    ...data,
                    black_list: userList,
                    white_list: userList 
                });
                setPermissionType(data.black_list.length 
                    ? PermissionType.BLACK_LIST
                    : data.white_list.length
                        ? PermissionType.WHITE_LIST
                        : data.visible 
                            ? PermissionType.OPEN
                            : PermissionType.PRIVATE);
            });
        }
    }, [folderId, roles]);

    const handleEditFields = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        setFolderData((prevState) => {
            return {
                ...prevState,
                [name]: value,
            }
        })
    }

    const errorHandler = (error: any) => {
        const errorFields = Object.entries(error.response.data).map(([field, messages]) => ({
            field,
            // @ts-ignore
            message: messages[0],
        }));
        setErrorFields(errorFields);
    }

    const onHandleSubmit = (e: any) => {
        e.preventDefault();
        const data: Folder = permissionType == PermissionType.WHITE_LIST
        ? {name: folderData.name, white_list: folderData.white_list?.map(it => it.id)}
        : permissionType == PermissionType.BLACK_LIST 
            ? {name: folderData.name, black_list: folderData.black_list?.map(it => it.id)}
            : {name: folderData.name, visible: permissionType === PermissionType.OPEN ? true : false}

        if (!isEdit) {
            parentFolderId 
                ? postCreateFolderInFolderInRoot(parentFolderId, data).then(() => {
                    onSave();
                })
                .catch((error) => {
                    errorHandler(error);

                })
                : postCreateFolderInRoot(data).then(() => {
                    onSave();
                })
                .catch((error) => {
                    errorHandler(error);

                });
        } else if (folderId) {
            if (permissionType === PermissionType.BLACK_LIST) {
                data.white_list = [];
            } 
            if (permissionType === PermissionType.WHITE_LIST) {
                data.black_list = [];
            }
            if (permissionType === PermissionType.OPEN || permissionType === PermissionType.PRIVATE) {
                data.black_list = [];
                data.white_list = [];
            }
            updateFoldert(folderId, data).then(() => {
                onSave();
            })
            .catch((error) => {
                errorHandler(error);

            })
        }
    }

    const recipientTypeRadios: Radio[] = [
        {
          value: PermissionType.OPEN,
          label: <RadioRowLabelContainer>{t('ui.modal.permisыion.open')} <span>{t('ui.modal.permisыion.openSecond')}</span></RadioRowLabelContainer>,
        },
        {
          value: PermissionType.PRIVATE,
          label: <RadioRowLabelContainer>{t('ui.modal.permisыion.private')} <span>{t('ui.modal.permisыion.privateSecond')}</span></RadioRowLabelContainer>,
        },
        {
          value: PermissionType.WHITE_LIST,
          label: <RadioRowLabelContainer>{t('ui.modal.permisыion.whiteList')} <span>{t('ui.modal.permisыion.whiteListSecond')}</span></RadioRowLabelContainer>,
        },
        {
          value: PermissionType.BLACK_LIST,
          label: <RadioRowLabelContainer>{t('ui.modal.permisыion.blackList')} <span>{t('ui.modal.permisыion.blackListSecond')}</span></RadioRowLabelContainer>,
        },
    ];
    const onSelect = (selectedList: any, selectedItem: any) => {
        setFolderData((prevState) => {
            return {
                ...prevState,
                black_list: selectedList,
                white_list: selectedList
            }
        })
    }
    
    const onRemove = (selectedList: any, removedItem: any) => {
        setFolderData((prevState) => {
            return {
                ...prevState,
                black_list: selectedList,
                white_list: selectedList
            }
        })
    }

    return (
        <StyledModal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <StyledBox>
                <Cross onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#475F7B"/>
                    </svg>
                </Cross>
                <StyledForm onSubmit={onHandleSubmit}>
                    <StyledTitle>{isEdit ? t('ui.modal.title.editFolder') : t('ui.modal.title.createFolder')}</StyledTitle>
                    <Input name='name' value=
                    {folderData?.name} disabled={!hasPermision(3) && isEdit} onChange={handleEditFields} label={t('ui.input.name')} placeholder={t('ui.input.userName')} errors={errorFields}></Input>
                    <SettinPermissionContainer><IconUnlock />{t('ui.modal.title.permissionSetting')}</SettinPermissionContainer>
                    {(permissionType == PermissionType.BLACK_LIST || permissionType == PermissionType.WHITE_LIST) && (
                        <div>
                            <Label>{!isEdit ? t('ui.input.selectUsers') 
                                    : permissionType == PermissionType.BLACK_LIST 
                                        ? t('ui.input.selectUsersBlackList') 
                                        : t('ui.input.selectUsersWhiteList')}
                            </Label>
                            <MultiSelect
                                options={roles?.map((role: any) => { return {id: role.id, name: role.name}})} // Options to display in the dropdown
                                selectedValues={permissionType == PermissionType.BLACK_LIST 
                                    ? (folderData?.black_list ?? [])
                                    : (folderData?.white_list ?? [])} // Preselected value to persist in dropdown
                                onSelect={onSelect} // Function will trigger on select event
                                onRemove={onRemove} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                                closeIcon={'cancel'}
                                placeholder={''}
                                showArrow={true}
                                disable={!hasPermision(3) && isEdit}
                                customArrow={<IconDropperDown />}
                            />
                        </div>
                        // <Select
                        //     value={permissionType == PermissionType.BLACK_LIST 
                        //         ? (folderData?.black_list ?? [])
                        //         : (folderData?.white_list ?? [])}
                        //     color="secondary"
                        //     name={permissionType == PermissionType.BLACK_LIST 
                        //         ? 'black_list'
                        //         : 'white_list'}
                        //         label={!isEdit ? t('ui.input.selectUsers') 
                        //         : permissionType == PermissionType.BLACK_LIST 
                        //             ? t('ui.input.selectUsersBlackList') 
                        //             : t('ui.input.selectUsersWhiteList')}
                        //     onChange={handleEditFields}
                        //     errors={errorFields}
                        //     multiple
                        //     options={roles?.map((role: any) => { return {id: role.id, name: role.name}})}>
                        //         {roles?.map((role: any) => (
                        //             <MenuItem key={role.id} value={role.id}>
                        //                 {role.name}
                        //             </MenuItem>
                        //         ))}
                        // </Select>
                    )}
                    <RadioGroup
                        disabled={!hasPermision(3) && isEdit}
                        value={permissionType}
                        radios={recipientTypeRadios}
                        onChange={(e) => setPermissionType(e as PermissionType)}
                    />
                    {isEdit && (<Link onClick={() => navigator.clipboard.writeText(`${window.location.origin}/folders/${folderId}`)} text={'Копировать ссылку'}></Link>)}
                    {hasPermision(1) && !isEdit && (<Button type={'submit'} variant="contained" body={t('ui.button.create')}></Button>)}
                    {hasPermision(3) && isEdit && (<Button type={'submit'} variant="contained" body={t('ui.button.update')}></Button>)}

                </StyledForm>
            </StyledBox>
        </StyledModal>
    );
};

export default SaveEditFolderModal;
