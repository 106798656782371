import styled from 'styled-components';
import { IconButton as MuiIconButton } from '@mui/material';

export const IconButton = styled(MuiIconButton)`
  width: fit-content;
`;

export const Icon = styled.img`
  max-width: 100%;
  object-fit: contain;
`;
