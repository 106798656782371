import React, { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setAlert, setProgress, setFiles } from '../../store/downloadFile';
import { useHandleFileChange } from '../../hooks/useHandleFileChange';

interface DownloadProps {
  label?: string;
  id?: number;
  onFileNameChange?: (fileName: string) => void;
  cancelCondition: boolean;
  handleFileChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number | undefined
  ) => Promise<void>;
}

export default function Dow(props: DownloadProps) {
  const { label, id, onFileNameChange, cancelCondition, handleFileChange } =
    props;
  const file = useSelector((state: RootState) => state.fileUpload.file);
  const alertText = useSelector(
    (state: RootState) => state.fileUpload.alertText
  );
  const alert = useSelector((state: RootState) => state.fileUpload.alert);
  const flag = useSelector((state: RootState) => state.fileUpload.flag);

  return (
    <div>
      {/*{label && <label>{label}</label>}*/}
      <div>
        <label
          htmlFor='file-upload'
          style={{ color: '#006666', cursor: 'pointer' }}
        >
          + Добавить файл
        </label>
        <input
          id='file-upload'
          type='file'
          accept='.svs,.kfb,.tif,.ndpi'
          onChange={(e) => handleFileChange && handleFileChange(e, id)}
          style={{ display: 'none' }}
          multiple={true}
        />
      </div>
    </div>
  );
}
